import React from 'react';
import ReactDom from 'react-dom';

class Modal extends React.Component {
  constructor() {
    super();
    this.el = document.getElementById('modal-root');
  }
  componentDidMount() {
    setTimeout(() => {
      this._modal.classList.add("showing");
    }, 0);
    //document.querySelector('.modalBox input, .modalBox button').focus();
  }
  closeNow() {
    this._modal.classList.remove("showing");
    setTimeout(() => {
      this.props.onClick();
    }, 200)
  }
  render() {
    return ReactDom.createPortal(
      <div ref={modal => this._modal = modal } className="modalBack" onClick={() => this.closeNow()}>
        <div onClick={(e) => e.stopPropagation()} className="modalBox">
          { this.props.children }
        </div>
      </div>,
      this.el
    );
  }
}

export default Modal;
