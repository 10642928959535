import React from 'react';
import Slider from '../Slider.jsx';
import PropertyHelper from '../../PropertyHelper.js';
import NumberTuner from '../NumberTuner.jsx';

const WaveChooser = ({ waves, selectedWave, onSelect}) => {
  let buttons = Object.keys(waves).map((waveName, index) => {
    let waveValue = waves[waveName];
    let selectedClass = waveValue == selectedWave ? 'selected' : '';
    return <button key={index} onClick={() => onSelect(waveValue)} className={`waveChooserButton ${selectedClass}`}> {waveName}</button>
  })
  return (
    <div className="waveChooser">
     { buttons }
    </div>
  )
}

const OnOffButton = ({name, isOn, onClick}) => {
  return (
    <button onClick={() => onClick(!isOn)} className={`onOffButton ${isOn ? 'on' : 'off'}`}>{name}</button>
  )
}

class ADSR extends React.Component {
  componentDidMount() {
    this._drawCanvas();
  }
  componentDidUpdate() {
    this._drawCanvas();
  }
  _drawCanvas() {
    let sustainLength = 50;
    let topStart = this._canvas.height * .1;
    let totalWidth = this._a + this._d + this._r + sustainLength;
    let aX = this._a / totalWidth * this._canvas.width;
    let dX = this._d / totalWidth * this._canvas.width + aX;
    let sX = sustainLength / totalWidth * this._canvas.width + dX;
    let sY = this._canvas.height - this._canvas.height * .9 * this._s / 10;
    let rX = this._canvas.width - this._canvas.width * this._r / totalWidth;

    let ctx = this._canvas.getContext('2d');
    ctx.strokeStyle = "white";
    ctx.clearRect(0, 0, this._canvas.width, this._canvas.height);
    ctx.beginPath();
    ctx.moveTo(0, this._canvas.height);
    ctx.lineTo(aX, topStart);
    //ctx.moveTo(aX, topStart);
    ctx.lineTo(dX, sY);
    //ctx.moveTo(dX, sY);
    ctx.lineTo(sX, sY);
    //ctx.moveTo(sX, sY);
    ctx.lineTo(this._canvas.width, this._canvas.height);
    ctx.stroke();
  }
  _getCurrentValues() {
    let {attack, decay, sustain, release, playPosition} = this.props;
    this._a = PropertyHelper.getCurrentValueAndValueDirectlyAfter(attack, playPosition).currentValue / attack.unit;
    this._d = PropertyHelper.getCurrentValueAndValueDirectlyAfter(decay, playPosition).currentValue / decay.unit;
    this._s = PropertyHelper.getCurrentValueAndValueDirectlyAfter(sustain, playPosition).currentValue / sustain.unit;
    this._r = PropertyHelper.getCurrentValueAndValueDirectlyAfter(release, playPosition).currentValue / release.unit;
    return { a: this._a, d: this._d, s: this._s, r: this._r };
  }

  render() {
    // these are hte property objects, not just values
    let {attack, decay, sustain, release, playPosition, onInput} = this.props;
    let { a, d, s, r } = this._getCurrentValues();

    let onInp = (propertyInfo) => {
      return (e) => {
        onInput(propertyInfo.id, e.target.value)
      }
    }

    return (
      <div className="adsrSetter">
        <div className="adsrSetterCanvasWrapper">
          <canvas className="adsrSetterCanvas" ref={(el) => this._canvas = el } width="230" height="50" />
        </div>
        <div className="adsrSetterSliders">
          <Slider label={`Attack ${Number(a).toPrecision(3)}`} onChange={() => {}} onInput={onInp(attack)} min={attack.min} max={attack.max} step={1} value={a} />
          <Slider label={`Decay ${Number(d).toPrecision(3)}`} onChange={() => {}} onInput={onInp(decay)} min={decay.min} max={decay.max} step={1} value={d} />
          <Slider label={`Sustain ${Number(s).toPrecision(3)}`} onChange={() => {}} onInput={onInp(sustain)} min={sustain.min} max={sustain.max} step={1} value={s} />
          <Slider label={`Release ${Number(r).toPrecision(3)}`} onChange={() => {}} onInput={onInp(release)} min={release.min} max={release.max} step={1} value={r} />
        </div>
      </div>
    );
  }
}

export {
  WaveChooser,
  OnOffButton,
  ADSR,
};
