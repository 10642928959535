import React from 'react';
import { NavLink } from 'react-router-dom';
import Sequencer from './Sequencer.jsx';
import ToolMenu from './ToolMenu.jsx';
import TimeBar from './TimeBar.jsx';
import Seeker from './Seeker.jsx';
import TrackList from './TrackList.jsx';
import SoundPlayer from './SoundPlayer.jsx';
import PropertyViewer from './PropertyViewer.jsx';
import { connect } from 'react-redux';
import { VIEWMODES } from '../constants.js';
import { setViewMode } from '../actions/tracks.js';
import Rack from './devices/Rack.jsx';

class Editor extends React.Component {
  _getView() {
    switch (this.props.track.viewMode) {
      case VIEWMODES.sequencer: {
        return <Sequencer />
      }
      case VIEWMODES.properties: {
        return <PropertyViewer />
      }
    }
  }
  render() {
    return (
      <section>
        <div className="editorWrapper">
          <div className="leftColumn">
            <ToolMenu />
            <TrackList />
          </div>
          <div className="rightColumn">
            {
              this.props.track &&
              <React.Fragment>
                <Seeker />
                <TimeBar />
                {
                  this._getView()
                }
                <div className="modeSelector">
                  {
                    Object.keys(VIEWMODES).map((mode, index) => {
                      let selectedClass = VIEWMODES[mode] == this.props.track.viewMode ? 'selected' : '';
                      let onClick = (e) => {
                        this.props.dispatch(setViewMode(VIEWMODES[mode]));
                      };
                      return <button className={`modeSelectorButton ${selectedClass}`} key={index} onClick={onClick}>{VIEWMODES[mode]} view</button>
                    })
                  }
                </div>
              </React.Fragment>
            }
            </div>
          <SoundPlayer />
        </div>
      </section>
    )
  }
}

let mapStateToProps = (state, props) => {
  return {
    track: state.tracks.tracks[state.tracks.selectedTrack],
  }
}

export default connect(mapStateToProps)(Editor);
