import React from 'react';
import { connect } from 'react-redux';
import { advancePlayPosition } from '../actions/song.js';
import PropertyHelper from '../PropertyHelper.js';
import constants from '../constants.js';
import { NOTES, PROPERTIES } from '../constants.js';
import AudioChain from './audio/AudioChain.js';
import GGain from './audio/GGain.js';
import GOscillator from './audio/GOscillator.js';
import ADCtx from './audio/ADCtx.js'

class SoundPlayer extends React.Component {
  constructor() {
    super();
    this._timeout = null;
    this._playPosition = null;
    this._instruments = {};

    // let mod = this._ctx.createOscillator();
    // mod.frequency.value = 500;
    // mod.start();
    // let gain = this._ctx.createGain();
    // gain.gain.setValueAtTime(0, this._ctx.currentTime);
    // gain.gain.linearRampToValueAt Time(1000, this._ctx.currentTime + 2);
    //
    // let osc = this._ctx.createOscillator();
    // osc.start();
    // mod.connect(gain);
    // gain.connect(osc.frequency);
    // osc.connect(this._ctx.destination);
    // osc.frequency.value = 440;


    // let osc = this._ctx.createOscillator();
    // let mod = this._ctx.createOscillator();
    // let modGain = this._ctx.createGain();
    // let oscGain = this._ctx.createGain();
    // mod.connect(modGain.gain);
    // modGain.connect(osc.frequency);
    // osc.connect(oscGain.gain);
    // oscGain.connect(this._ctx.destination);
    // osc.frequency.value = 440;
    // mod.frequency.value = 880;
    // osc.start();
    // mod.start();
    // this._masterOut.setValueAtTime(1, this._ctx.currentTime);

    // this._limiter = this._audioContext.createDynamicsCompressor();
    // this._limiter.threshold.setValueAtTime(-50, this._audioContext.currentTime);
    // this._limiter.knee.setValueAtTime(0, this._audioContext.currentTime);
    // this._limiter.ratio.setValueAtTime(40, this._audioContext.currentTime);
    // this._limiter.attack.setValueAtTime(0, this._audioContext.currentTime);
    // this._limiter.release.setValueAtTime(0.25, this._audioContext.currentTime);
    //
    // this._limiter.connect(this._audioContext.destination);

    // let osc = new GOscillator(this._audioContext);
    // osc.setEnvelope(0, 0, 1, 0);
    // osc.setNewNote({ timeStart: 0, timeEnd: 10, key: 30});
    // osc.connect(this._masterOut);


    // let properties = {
    //   [PROPERTIES.slide]: { currentValue: 1},
    //   [PROPERTIES.slideTime]: { currentValue: 1},
    //   [PROPERTIES.attack]: { currentValue: 0},
    //   [PROPERTIES.decay]: { currentValue: .5},
    //   [PROPERTIES.sustain]: { currentValue: .5},
    //   [PROPERTIES.release]: { currentValue: 1},
    //   [PROPERTIES.wave]: { currentValue: 1},
    // }
    // let mono = new MonoSynth(this._audioContext);
    // mono.connect(this._masterOut);
    // mono.setDeviceProperties(properties);
    // mono.setNotes(5, [{timeStart:0, timeEnd: 10, key: 48}]);
    // mono.play();

    // let properties = {
    //   [PROPERTIES.slide]: { currentValue: 1},
    //   [PROPERTIES.slideTime]: { currentValue: 1},
    //   [PROPERTIES.attack]: { currentValue: 0},
    //   [PROPERTIES.decay]: { currentValue: .5},
    //   [PROPERTIES.sustain]: { currentValue: .5},
    //   [PROPERTIES.release]: { currentValue: 1},
    //   [PROPERTIES.wave]: { currentValue: 1},
    // }
    // let deviceChain = JSON.parse(`[{"id":-9007199254740990,"name":"MonoSynth","type":"monosynth","properties":{"Attack":-9007199254740989,"Decay":-9007199254740988,"Sustain":-9007199254740987,"Release":-9007199254740986,"Slide":-9007199254740985,"SlideTime":-9007199254740984,"Wave":-9007199254740983},"nextDevice":null}]`);
    // let musicData = JSON.parse(`{"loopTime":50,"notes":[{"id":0,"key":48,"timeStart":21,"timeEnd":41,"selected":true,"clickedTime":22,"clickedKey":67,"showingTime":0}],"playPosition":23,"trackProperties":{"Pitch":{"currentValue":0,"nextValue":0}},"instrumentProperties":[{"Attack":{"currentValue":0,"nextValue":0,"changeTime":50},"Decay":{"currentValue":0,"nextValue":0,"changeTime":50},"Sustain":{"currentValue":5,"nextValue":5,"changeTime":50},"Release":{"currentValue":0,"nextValue":0,"changeTime":50},"Slide":{"currentValue":0,"nextValue":0,"changeTime":50},"SlideTime":{"currentValue":0,"nextValue":0,"changeTime":50},"Wave":{"currentValue":0,"nextValue":0,"changeTime":50}}]}`);
    // musicData.instrumentProperties = [properties];
    // let ac = new AudioChain(this._audioContext, this._masterOut);
    // ac.setChainData(deviceChain);
    // ac.setMusicData(musicData);
    // setTimeout(() => {
    //   ac.clearNotesAndStop();
    //
    //   musicData.playPosition = 40;
    //   musicData.notes[0] = {id: 3, timeStart: 40, timeEnd:101, key: 60}
    //   ac.setChainData(deviceChain);
    //   ac.setMusicData(musicData);
    // }, 1000)
  }
  componentDidMount(props) {
    this._playPosition = this.props.playPosition;
    this._runLoop();
  }
  shouldComponentUpdate(props) {
    if (this._playPosition != props.playPosition) {
       this._stopInstruments();
      this._playPosition = props.playPosition;
    }
    //playing note is the note a user is manually pressing with the speaker tool
    if (this.props.playingNote != props.playingNote) {
      //this._playUserInput(props.playingNote);
    }
    return false;
  }
  _playUserInput(key) {
  }
  _playingUserInput() {
    return this.props.playingNote != null;
  }
  _runLoop() {
    let loopTime = this._getLoopTime();
    this._timeout = setTimeout(() => {
      this._runLoop()
    }, loopTime);

    let secondsLoopTime = loopTime / 1000;
    if (this.props.playing) {
      if (this._playPosition == this.props.startPosition) {
        this._stopInstruments();
      }
      this._updateMasterVolume(secondsLoopTime);
      this._setMusicalInformationInInstruments(secondsLoopTime);
      this.props.dispatch(advancePlayPosition(this._playPosition));
      this._playPosition++;
      if (this._playPosition == this.props.endPosition) {
        this._playPosition = this.props.startPosition;
      }
    } else {
      this._stopInstruments();
    }
  }
  _getLoopTime() {
    let currentBPM = PropertyHelper.getCurrentValueAndValueDirectlyAfter(this.props.bpmProperty, this._playPosition).currentValue;
    return (60000 / currentBPM) / ((1/4) / (1/constants.minNoteDivision));
  }
  _stopInstruments() {
    let tracks = this.props.tracks;
    Object.keys(tracks).forEach((trackId) => {
      let track = tracks[trackId];
      if (this._instruments[track.id]) {
        this._instruments[track.id].clearNotesAndStop();
      }
    });
  }
  _updateMasterVolume(loopTime) {
    let volume = PropertyHelper.getCurrentValueAndValueDirectlyAfter(this.props.masterVolumeProperty, this._playPosition);
    let master = ADCtx.getMasterOut();
    let ctx = ADCtx.getContext();
    master.setValueAtTime(volume.currentValue, ctx.currentTime);
    master.setTargetAtTime(volume.nextValue, ctx.currentTime, loopTime);
  }
  _setMusicalInformationInInstruments(loopTime) {
    let tracks = this.props.tracks;
    Object.keys(tracks).forEach((trackId) => {
      let track = tracks[trackId];

      let musicData = {
        loopTime: loopTime,
        // data about what notes are playing
        notes: [],
        playPosition: this._playPosition,
        // properties of the track like pitch
        trackProperties: {},
        // properties of the various devices in the instrument and effects
        instrumentProperties: [],
      };

      let point = track.points[this._playPosition];
      if (point) {
        musicData.notes = Object.keys(point).map((noteId) => {
          return track.notes[noteId];
        });
      }

      for (let key in track.properties) {
        let property = this.props.properties[track.properties[key]];
        let { currentValue, nextValue } = PropertyHelper.getCurrentValueAndValueDirectlyAfter(property, this._playPosition);
        musicData.trackProperties[key] = {
          currentValue,
          nextValue
        };
      }
      let deviceChain = [];
      let device = this.props.audioDevices[track.instrumentId];
      while (true) {
        let deviceProperties = {
        };
        // find the current values in the various properites of the device and store them
        // in 'propertyValues'
        for (let key in device.properties) {
          let property = this.props.properties[device.properties[key]];
          let { currentValue, nextValue } = PropertyHelper.getCurrentValueAndValueDirectlyAfter(property, this._playPosition);
          deviceProperties[key] = {
            currentValue,
            nextValue,
            changeTime: loopTime,
          };
        }
        musicData.instrumentProperties.push(deviceProperties);
        deviceChain.push(device);
        if (device.nextDevice == null) {
          break;
        }
        device = this.props.audioDevices[device.nextDevice];
      }
      if (!this._instruments[track.id]) {
        this._instruments[track.id] = new AudioChain(ADCtx.getContext(), ADCtx.getMasterOut());
      }
      this._instruments[track.id].setChainData(deviceChain);
      this._instruments[track.id].setMusicData(musicData);
    });
  }
  render() {
    return (
      <div></div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    playing: state.song.playing,
    playPosition: state.song.playPosition,
    startPosition: state.song.startPosition,
    endPosition: state.song.endPosition,
    bpmProperty: state.properties[state.song.properties[PROPERTIES.bpm]],
    masterVolumeProperty: state.properties[state.song.properties[PROPERTIES.volume]],
    tracks: state.tracks.tracks,
    properties: state.properties,
    selectedTrack: state.tracks.selectedTrack,
    playingNote: state.song.playingNote,
    audioDevices: state.audioDevices,
  }
}
const mapDispatchToProps = (dispatch, props) => {
  return {
    dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SoundPlayer);
