class GAudioNode {
  constructor(context) {
    this._ctx = context;
    this._receiver = null;
    this._outputNode = null;
    this._soundSources = 0;
  }
  setDeviceProperties(properties) {
    // should be overridden
  }
  incrementSoundSources() {
    this._soundSources++;
  }
  decrementSoundSources() {
    this._soundSources--;
  }
  connect(receiver) {
    if (this._receiver != null) {
      console.log("ALREADY CONNNECTED");
      return;
    }
    this._receiver = receiver;
    if (receiver instanceof GAudioNode) {
      this._receiver.getInputNodes().forEach((inp) => {
        this._outputNode.connect(inp);
        this._receiver.incrementSoundSources();
      })
    } else {
      this._outputNode.connect(this._receiver);
    }
  }
  disconnect() {
    if (this._receiver) {
      if (this._receiver instanceof GAudioNode) {
        this._receiver.getInputNodes().forEach((inp) => {
          this._outputNode.disconnect(inp);
        });
        this._receiver.decrementSoundSources();
      } else {
        // we were connected to destination probably
        this._outputNode.disconnect(this._receiver);
      }
      this._receiver = null;
    }
  }
  getInputNodes() {
    // should be overriidden
    throw Error("Why hasn't getInputNodes been overridden?")
  }
  destroy() {
    if (this._receiver) {
      if (this._receiver instanceof GAudioNode) {
        this._receiver.getInputNodes().forEach((inp) => {
          this._outputNode.disconnect(inp);
        });
      } else {
        // propbably means this is directly connected to the audiocontext destination
        this._outputNode.disconnect(this._receiver);
      }
      this._receiver = null;
    }
    this._destroyInputs();
    this._outputNode = null;
  }
  _destroyInputs() {
    // should be overridden.
    // needs custom treatment
  }
}

export default GAudioNode;
