import React from 'react';
import Modal from '../Modal.jsx';
import { DEVICES } from '../../constants.js';
import FMMultiSynth from './FMMultiSynth.jsx';

class Rack extends React.Component {
  constructor() {
    super();
  }
  render() {
    let devices = this.props.devices.map((device, index) => {
      switch (device.type) {
        case DEVICES.fmMultiSynth: {
          return <FMMultiSynth key={index} deviceData={device} />
        }
      }
    });
    return (
      <Modal onClick={this.props.close}>
          <div className="modalContent">
            { devices }
          </div>
      </Modal>
    )
  }
}

export default Rack;
