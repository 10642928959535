import { PROPERTIES } from '../constants.js'

import {
  createProperty,
  setSelectedProperty,
} from './properties.js';
import {
  createFMMultiSynth
} from './audioDevices.js'

export const addNewTrack = () => {
  return (dispatch, getState) => {
    dispatch(createProperty(PROPERTIES.pitch, 0, 1, -1000, 1000, true));
    let pitchId = getState().properties.lastPropertyId;
    dispatch(createProperty(PROPERTIES.volume, 8, .1, 0, 10, true));
    let volumeId = getState().properties.lastPropertyId;
    let instrumentId = dispatch(createFMMultiSynth());
    dispatch({
      type: 'addNewTrack',
      pitchId,
      volumeId,
      instrumentId,
    });
    dispatch(setSelectedProperty(pitchId));
  }
}

export const setSelectedTrack = (index) => {
  return (dispatch, getState) => {
    dispatch({ type: 'setSelectedTrack', index });
    let tracks = getState().tracks;
    let selectedProperty = tracks.tracks[tracks.selectedTrack].selectedProperty;
    dispatch(setSelectedProperty(selectedProperty));
  }
}

export const setMagnification = (magnification) => {
  return {
    type: 'setMagnification',
    magnification
  };
};

export const beginAdjustingSequencerWindow = (showingValue, showingTime) => {
  return {
    type: 'beginAdjustingSequencerWindow',
    showingValue,
    showingTime
  }
}

export const adjustSequencerWindow = (noteValue, propertyValue, time, adjustLastValues = false) => {
  return {
    type: 'adjustSequencerWindow',
    time,
    noteValue,
    propertyValue,
    adjustLastValues
  };
};

export const createNote = (key, timeStart, timeEnd) => {
  return {
    type: 'createNote',
    key,
    timeStart,
    timeEnd
  }
};

export const startSelectingPoint = (startSelectingValue, startSelectingTime) => {
  return {
    type: 'startSelectingPoint',
    startSelectingValue,
    startSelectingTime
  }
}

export const setCurrentSelectingPoint = (currentSelectingValue, currentSelectingTime) => {
  return {
    type: 'setCurrentSelectingPoint',
    currentSelectingValue,
    currentSelectingTime
  }
}

export const setSelectedNotes = (ids) => {
  return {
    type: 'setSelectedNotes',
    ids: ids || []
  }
};

export const editNotes = (notes) => {
  return {
    type: 'editNotes',
    notes,
  }
}


export const startExpandingSelections = (isLeft) => {
  return {
    type: 'startExpandingSelections',
    isLeft,
  }
}
export const startExpandingNewSelection = (selectionId, isLeft) => {
  return {
    type: 'startExpandingNewSelection',
    selectionId,
    isLeft,
  }
}

export const startMovingNotes = (isLeft) => {
  return {
    type: 'startMovingNotes',
    isLeft,
  }
}
export const startMovingNewlySelectedNote = (selectionId, isLeft) => {
  return {
    type: 'startMovingNewlySelectedNote',
    selectionId,
    isLeft,
  }
}

export const cutNote = (noteId, time) => {
  return {
    type: 'cutNote',
    noteId,
    time,
  }
}

export const deleteSelectedNotes = () => {
  return {
    type: 'deleteSelectedNotes',
  }
}

export const undo = () => {
  return {
    type: 'undo',
  }
}

export const setCurrentAction = (actionName) => {
  return {
    type: 'setCurrentAction',
    currentAction: actionName
  }
}

export const changeZoom = (zoomDiff) => {
  return {
    type: 'changeZoom',
    zoomDiff
  }
}

export const pasteCopiedItems = (copyObject) => {
  return {
    type: 'pasteCopiedItems',
    copyObject
  };
};

export const setViewMode = (mode) => {
  return {
    type: 'setViewMode',
    mode
  }
}
