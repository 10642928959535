import { createProperty } from './properties.js';

export const createMonoSynth = () => {
  return (dispatch, getState) => {
    dispatch(createProperty('volume', 8, .1, 0, 10, true));
    let volumeId = getState().properties.lastPropertyId;

    dispatch(createProperty('attack', 0, .01, 0, 500, true));
    let attackId = getState().properties.lastPropertyId;

    dispatch(createProperty('decay', 10, .01, 0, 500, true));
    let decayId = getState().properties.lastPropertyId;

    dispatch(createProperty('sustain', 5, .1, 0, 10, true));
    let sustainId = getState().properties.lastPropertyId;

    dispatch(createProperty('release', 10, .01, 0, 500, true));
    let releaseId = getState().properties.lastPropertyId;

    dispatch(createProperty('slide', 1, 1, 0, 1, false));
    let slideId = getState().properties.lastPropertyId;

    dispatch(createProperty('slideTime', 5, .01, 0, 100, true));
    let slideTimeId = getState().properties.lastPropertyId;

    dispatch(createProperty('wave', 0, 1, 0, 4, false));
    let waveId = getState().properties.lastPropertyId;

    dispatch({
      type: 'createSynth',
      volumeId,
      attackId,
      decayId,
      sustainId,
      releaseId,
      slideId,
      slideTimeId,
      waveId,
    });
    let synthId = getState().audioDevices.lastDeviceId;
    return synthId;
  }
}

export const createFMMultiSynth = () => {
  return (dispatch, getState) => {
    dispatch(createProperty('volume', 8, .1, 0, 10, true));
    let volumeId = getState().properties.lastPropertyId;

    dispatch(createProperty('attack', 0, .01, 0, 500, true));
    let attackId = getState().properties.lastPropertyId;

    dispatch(createProperty('decay', 10, .01, 0, 500, true));
    let decayId = getState().properties.lastPropertyId;

    dispatch(createProperty('sustain', 5, .1, 0, 10, true));
    let sustainId = getState().properties.lastPropertyId;

    dispatch(createProperty('release', 10, .01, 0, 500, true));
    let releaseId = getState().properties.lastPropertyId;

    dispatch(createProperty('slide', 1, 1, 0, 1, false));
    let slideId = getState().properties.lastPropertyId;

    dispatch(createProperty('slideTime', 5, .01, 0, 100, true));
    let slideTimeId = getState().properties.lastPropertyId;

    dispatch(createProperty('wave', 0, 1, 0, 4, false));
    let waveId = getState().properties.lastPropertyId;

    dispatch(createProperty('multi', 0, 1, 0, 1, false));
    let multiId = getState().properties.lastPropertyId;

    dispatch(createProperty('isFM', 0, 1, 0, 1, false));
    let isFMId = getState().properties.lastPropertyId;

    dispatch(createProperty('fmAttack', 0, .01, 0, 500, true));
    let fmAttackId = getState().properties.lastPropertyId;

    dispatch(createProperty('fmDecay', 10, .01, 0, 500, true));
    let fmDecayId = getState().properties.lastPropertyId;

    dispatch(createProperty('fmSustain', 5, .1, 0, 10, true));
    let fmSustainId = getState().properties.lastPropertyId;

    dispatch(createProperty('fmRelease', 10, .01, 0, 500, true));
    let fmReleaseId = getState().properties.lastPropertyId;

    dispatch(createProperty('fmWave', 0, 1, 0, 4, false));
    let fmWaveId = getState().properties.lastPropertyId;

    dispatch(createProperty('fmRatio', 8, 0.125, 1, 128, true));
    let fmRatioId = getState().properties.lastPropertyId;

    dispatch(createProperty('fmMax', 2, 1, 1, 10000, true));
    let fmMaxId = getState().properties.lastPropertyId;

    dispatch({
      type: 'createFMMultiSynth',
      volumeId,
      attackId,
      decayId,
      sustainId,
      releaseId,
      slideId,
      slideTimeId,
      waveId,
      multiId,
      isFMId,
      fmAttackId,
      fmDecayId,
      fmSustainId,
      fmReleaseId,
      fmWaveId,
      fmRatioId,
      fmMaxId,
    });
    let synthId = getState().audioDevices.lastDeviceId;
    return synthId;
  }
}

export const setSelectedDevice = (deviceId) => {
  return {
    type: 'setSelectedDevice',
    deviceId
  }
}
