import React from 'react';
import { connect } from 'react-redux';
import { addNewTrack, setSelectedTrack } from '../actions/tracks.js';
import { PROPERTIES } from '../constants.js';
import Slider from './Slider.jsx';
import Rack from './devices/Rack.jsx';
import PropertyHelper from '../PropertyHelper.js';
import { editPropertyFromUI } from '../actions/properties.js';
import { setSelectedDevice } from '../actions/audioDevices.js';

class TrackList extends React.Component {
  constructor() {
    super();
  }
  addTrack() {
    this.props.dispatch(addNewTrack());
  }
  _changedVolume(e, propertyId) {
    this.props.dispatch(editPropertyFromUI(propertyId, e.target.value));
  }
  _setSelectedDevice(trackId) {
    let { tracks, dispatch } = this.props;
    dispatch(setSelectedDevice(tracks[trackId].instrumentId));
  }
  _getRack() {
    let { audioDevices } = this.props;
    if (audioDevices.selectedDevice === null) {
      return null;
    }
    let currentDevice = audioDevices[audioDevices.selectedDevice];
    let devices = [currentDevice];
    while (true) {
      if (currentDevice.nextDevice) {
        currentDevice = audioDevices[currentDevice.nextDevice];
        devices.push(currentDevice);
      } else {
        break;
      }
    }
    return <Rack close={() => this.props.dispatch(setSelectedDevice(null))} devices={devices} />;
  }

  render() {
    let { tracks, selectedTrack, playPosition, properties } = this.props;

    return (
      <div className="trackList">
        <div className="trackListButtons">
          <button onClick={() => this.addTrack()} className="trackListButton">New Track</button>
        </div>
        {
          Object.keys(tracks).map((trackId, index) => {
            let track = tracks[trackId];
            let volumeProperty = properties[tracks[trackId].properties[PROPERTIES.volume]];
            let currentVolume = PropertyHelper.getCurrentValueAndValueDirectlyAfter(volumeProperty, playPosition).currentValue / volumeProperty.unit;
            currentVolume = Number(currentVolume).toPrecision(2);

            return (
              <div key={index}
                   onClick={() => this.props.dispatch(setSelectedTrack(trackId))}
                   className={`trackListTrack ${track.id == selectedTrack ? 'selected' : ''}`}>
                <div className="trackListTopRow">
                  { `Track ${index}`}
                  <div onClick={() => this._setSelectedDevice(trackId)} className="trackListGear">
                    ⚙
                  </div>
                </div>
                <Slider label={"Volume " + currentVolume} onChange={() => {}} onInput={(e) => this._changedVolume(e, volumeProperty.id)} min={volumeProperty.min} max={volumeProperty.max} step={volumeProperty.unit} value={currentVolume} />
              </div>
            )
          })
        }
        { this._getRack() }
      </div>
    )
  }
}

let mapStateToProps = (state, props) => {
  let selectedTrack = state.tracks.tracks[state.tracks.selectedTrack];
  return {
    tracks: state.tracks.tracks,
    selectedTrack: state.tracks.selectedTrack,
    playPosition: state.song.playPosition,
    properties: state.properties,
    audioDevices: state.audioDevices,
  }
}
let mapDispatchToProps = (dispatch, props) => {
  return {
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TrackList);
