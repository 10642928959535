export const createProperty = (name, defaultValue = 0, unit = 1, min, max, isGradient) => {
  return {
    type: 'createProperty',
    name,
    defaultValue,
    unit,
    min,
    max,
    isGradient
  }
}

export const setSelectedProperty = (id) => {
  return {
    type: 'setSelectedProperty',
    id,
  };
}

export const createPropertyValue = (value, time, trackTime) => {
  return {
    type: 'createPropertyValue',
    value,
    time,
    trackTime,
  }
};

export const setSelectedPoints = (ids) => {
  return {
    type: 'setSelectedPoints',
    ids: ids || []
  }
};

export const startMovingPropertyPoints = (isLeft, trackTime) => {
  return {
    type: 'startMovingPropertyPoints',
    isLeft,
    trackTime,
  }
}
export const startMovingNewlySelectedPropertyPoint = (selectionId, isLeft, trackTime) => {
  return {
    type: 'startMovingNewlySelectedPropertyPoint',
    selectionId,
    isLeft,
    trackTime,
  }
}

export const deleteSelectedPropertyPoints = () => {
  return {
    type: 'deleteSelectedPropertyPoints',
  }
}

export const editPropertyPoints = (points, propertyId) => {
  return {
    type: 'editPropertyPoints',
    points,
    propertyId
  }
}

export const editPropertyFromUI = (propertyId, value) => {
  return {
    type: 'editPropertyFromUI',
    propertyId,
    value,
  }
}
