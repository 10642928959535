import constants from '../constants.js';
import { DEVICES, WAVES, PROPERTIES } from '../constants.js';


//
let defaultState = {
  lastDeviceId: -Number.MAX_SAFE_INTEGER,
  selectedDevice: null,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'createFMMultiSynth': {
      let newState = {
        ...state,
      }
      let id = ++newState.lastDeviceId;
      newState[id] = {
        id: id,
        name: 'FMMultiSynth',
        type: DEVICES.fmMultiSynth,
        properties: {
          [PROPERTIES.volume]: action.volumeId,
          [PROPERTIES.attack]: action.attackId,
          [PROPERTIES.decay]: action.decayId,
          [PROPERTIES.sustain]: action.sustainId,
          [PROPERTIES.release]: action.releaseId,
          [PROPERTIES.slide]: action.slideId,
          [PROPERTIES.slideTime]: action.slideTimeId,
          [PROPERTIES.wave]: action.waveId,
          [PROPERTIES.multi]: action.multiId,
          [PROPERTIES.isFM]: action.isFMId,
          [PROPERTIES.fmAttack]: action.fmAttackId,
          [PROPERTIES.fmDecay]: action.fmDecayId,
          [PROPERTIES.fmSustain]: action.fmSustainId,
          [PROPERTIES.fmRelease]: action.fmReleaseId,
          [PROPERTIES.fmWave]: action.fmWaveId,
          [PROPERTIES.fmRatio]: action.fmRatioId,
          [PROPERTIES.fmMax]: action.fmMaxId,
        },
        nextDevice: null,
      }
      return newState;
    }
    case 'setSelectedDevice':{
      let newState = {
        ...state
      };
      newState.selectedDevice = action.deviceId;
      return newState;
    }
    default: {
      return state;
    }
  }
}
