import GAudioNode from './GAudioNode.js';

// GGain is a gain that is directly preceded by a compressor

class GGain extends GAudioNode {
  constructor(context, flatten, noCompressor) {
    super(context);
    this._gain = this._ctx.createGain();
    this._gain.gain.setValueAtTime(0, this._ctx.currentTime);
    this._outputNode = this._gain;

    this._noCompressor = noCompressor;

    if (!noCompressor) {
      this._compressor = this._ctx.createDynamicsCompressor();
      this._compressor.threshold.setValueAtTime(flatten ? -100 : -20, this._ctx.currentTime);
      this._compressor.knee.setValueAtTime(0, this._ctx.currentTime);
      this._compressor.ratio.setValueAtTime(flatten ? 100 : 5, this._ctx.currentTime);
      this._compressor.attack.setValueAtTime(0, this._ctx.currentTime);
      this._compressor.release.setValueAtTime(0.25, this._ctx.currentTime);
      this._compressor.connect(this._gain);
    }
  }
  setValueAtTime(value, time) {
    //this._compressor.ratio.setValueAtTime(this._soundSources * 2, this._ctx.currentTime);
    this._gain.gain.setValueAtTime(value, time);
  }
  setTargetAtTime(value, startTime, changeTime) {
    //this._compressor.ratio.setValueAtTime(this._soundSources * 2, this._ctx.currentTime);
    this._gain.gain.setTargetAtTime(value, startTime, changeTime);
  }
  linearRampToValueAtTime(value, time) {
    this._gain.gain.linearRampToValueAtTime(value, time);
  }
  cancelScheduledValues(time) {
    this._gain.gain.cancelScheduledValues(time);
  }
  getValue() {
    return this._gain.gain.value;
  }
  getInputNodes() {
    if (!this._noCompressor) {
      return [this._compressor];
    } else {
      return [this._gain];
    }
  }
  _destroyInputs() {
    if (!this._noCompressor) {
      this._compressor.disconnect(this._gain);
      this._compressor = null;
    } else {
      // nothing needed
    }
  }
}

export default GGain;
