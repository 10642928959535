import 'babel-polyfill';

require("./scss/app.scss");

import React from 'react';
import ReactDom from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './js/App.jsx';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './js/reducers/index.js';

import { initializeSongProperties } from './js/actions/song.js';
import { addNewTrack } from './js/actions/tracks.js';
//import sagaRunner from './js/sagas/sagaRunner.js';

//const sagaMiddleware = createSagaMiddleware();
let store = createStore(
  rootReducer,
  applyMiddleware(thunk),
);


store.dispatch(initializeSongProperties());
store.dispatch(addNewTrack());

let hasWebAudio = window.AudioContext || window.webkitAudioContext || false;
if (!hasWebAudio) {
  let h1 = document.createElement('h1');
  h1.innerHTML = "Sorry, your browser is too old.";
  document.body.appendChild(h1);
} else {
  //sagaRunner(sagaMiddleware);

  let root = document.getElementById("react-root");

  ReactDom.render(
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>,
    root
  );

}
