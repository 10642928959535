let defaultState = {
  loggedIn: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'loggedIn': {
      let newState = {
        ...state,
        loggedIn: true
      }
      return newState;
    }
    default: {
      return state;
    }
  }
}
