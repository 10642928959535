import GAudioNode from './GAudioNode.js';
import { NOTES } from '../../constants.js';
import GGain from './GGain.js';

class GOscillator extends GAudioNode {
  constructor(context, noCompressor) {
    super(context);
    this._outputNode = new GGain(context, true, noCompressor)
    this._outputNode.setValueAtTime(0, this._ctx.currentTime);
    this._osc = this._ctx.createOscillator();
    this._osc.connect(this._outputNode.getInputNodes()[0]);
    this._osc.start();

    this._attack;
    this._decay;
    this._sustain;
    this._release;

    this._isSliding;
    this._slideTime;

    this._MAX_AMP = .5;
    this._MIN_TIME = .005;
    this._lastNote = null;
    this._multiplier = 1;
    this._disabled = false;

    this._noiseWave = this._createNoiseWave();

  }
  _createNoiseWave() {
    let seed = 1;
    let random = () => {
        let x = Math.sin(seed++) * 10000;
        return x - Math.floor(x);
    }

    let size = 1000;
    let real = new Float32Array(size);
    let imag = new Float32Array(size);

    for (let i = 0; i < size; i++) {

      real[i] = random() * (random() < .5 ? -1 : 1);
      imag[i] = random() * (random() < .5 ? -1 : 1);
    }
    return this._ctx.createPeriodicWave(real, imag);
  }
  setMaxAmp(max) {
    this._MAX_AMP = max;
  }
  setDisabled(isDisabled) {
    if (isDisabled == this._disabled) {
      return;
    }
    this._disabled = isDisabled;
    if (isDisabled) {
      this.clearNotes();
    }
  }
  setWave(wave) {
    if (this._lastWave && this._lastWave == wave) {
      return;
    }
    this._lastWave = wave;

    if (wave == 'noise') {
      this._osc.setPeriodicWave(this._noiseWave);
    } else {
      this._osc.type = wave;
    }
  }
  setMultiplier(multiplier) {
    this._multiplier = multiplier;
  }
  setSlide(isSliding, slideTime) {
    this._isSliding = isSliding ? true : false;
    this._slideTime = slideTime;
  }
  setEnvelope(attack, decay, sustain, release) {
    this._attack = attack;
    this._decay = decay;
    this._sustain = sustain;
    this._release = release;
  }
  setDetune(detuneNow, detuneNext, changeTime) {
    this._osc.detune.cancelScheduledValues(this._ctx.currentTime);
    this._osc.detune.linearRampToValueAtTime(detuneNow * 100, this._ctx.currentTime);
    this._osc.detune.linearRampToValueAtTime(detuneNext * 100, this._ctx.currentTime + (changeTime));
  }
  _getKeyFrequency(keyNumber) {
    let keyBase = keyNumber % 12;
    keyBase = keyBase < 0 ? 12 + keyBase : keyBase;
    let octaveMultiplier = Math.floor(keyNumber / 12) + 1;
    let baseFreq = NOTES[keyBase][1];
    return baseFreq * Math.pow(2, octaveMultiplier) * this._multiplier;
  }
  _beginEnvelope() {
    let attackStart = this._ctx.currentTime + this._MIN_TIME;
    let attackLength =  this._MIN_TIME + this._attack;
    let decayStart = attackStart + attackLength;
    let decayLength = this._decay + this._MIN_TIME;

    this._outputNode.cancelScheduledValues(this._ctx.currentTime);
    // start at 0 first. Can't go there instantly or we will get clicking sound
    this._outputNode.linearRampToValueAtTime(this._outputNode.getValue(), this._ctx.currentTime);
    this._outputNode.linearRampToValueAtTime(0, attackStart);
    this._outputNode.linearRampToValueAtTime(this._MAX_AMP, attackStart + attackLength);
    this._outputNode.linearRampToValueAtTime(this._sustain * this._MAX_AMP, decayStart + decayLength);
  }
  release() {
    if (this._lastNote) {
      this._outputNode.cancelScheduledValues(this._ctx.currentTime);
      this._outputNode.linearRampToValueAtTime(this._outputNode.getValue(), this._ctx.currentTime);
      this._outputNode.linearRampToValueAtTime(0, this._ctx.currentTime + this._release + this._MIN_TIME);
    }
    this._lastNote = null;
  }
  clearNotes() {
    this._lastNote = null;
    this._outputNode.cancelScheduledValues(this._ctx.currentTime);
    this._outputNode.setTargetAtTime(0, this._ctx.currentTime, 0);
  }
  isPlayingKey(key) {
    return this._lastNote && this._lastNote.key == key;
  }
  setNewNote(newNote) {
    if (this._disabled) {
      this._lastNote = newNote;
      return;
    }
    let osc = this._osc;
    let frequency = this._getKeyFrequency(newNote.key);
    if (this._lastNote) {
      if (newNote.id == this._lastNote.id) {
        return; // playing same note
      }
      if (this._isSliding) {
        if (newNote.timeStart < this._lastNote.timeEnd || (newNote.timeStart < this._lastNote.timeStart)) {
          osc.frequency.cancelScheduledValues(this._ctx.currentTime);
          osc.frequency.linearRampToValueAtTime(osc.frequency.value, this._ctx.currentTime + this._MIN_TIME);
          osc.frequency.linearRampToValueAtTime(frequency, this._ctx.currentTime + this._slideTime + this._MIN_TIME);
          this._lastNote = newNote;
          return;
        }
      }
    }
    osc.frequency.setValueAtTime(frequency, this._ctx.currentTime);
    this._lastNote = newNote;
    this._beginEnvelope();

    return;
  }
  _destroyInputs() {
    this._osc.disconnect(this._outputNode);
    this._osc = null;
  }
  getInputNodes() {
    return [this._osc];
  }
}

export default GOscillator;
