import constants from './constants.js';

// helper for working with Properties of Tracks
let PropertyHelper = {
  createNewProperty(id, name, defaultValue, unit, min, max, isGradient) {
    return {
      id,
      name,
      showingValue: -constants.keysOnScreen / 2,
      nextId: -Number.MAX_SAFE_INTEGER,
      selectedPoints: [],
      unit,
      min,
      max,
      isGradient,
      // there is always a 0 value, which is the pitch at the beginning of the song
      'start': {
        //
        id: 'start',
        time: 0,
        value: defaultValue,
        from: null,
        to: null,
      },
    };
  },
  getCopiedState(state, propertyId) {
    let newState = {
      ...state,
    }
    newState[propertyId] = {
      ...newState[propertyId]
    }
    let propertyInfo = newState[propertyId];
    return { newState, propertyInfo };
  },
  getShowingPropertyPoints(propertyInfo, track) {
    let showingTime = track.showingTime;
    let showingPoints = [];
    let logicalWidth = constants.viewWidth /  (constants.minNoteDivisionWidth * track.magnification);
    // keys represent time in the timeline.
    // there is guaranteed to be a point at 0 for the starting pitch
    let lastPoint = propertyInfo['start'];
    if (showingTime == 0) {
      showingPoints.push(lastPoint);
    }
    let nextPointKey = lastPoint.to;
    while (nextPointKey !== null && propertyInfo[nextPointKey].time < showingTime + logicalWidth) {
      let point = propertyInfo[nextPointKey];
      if (point.time >= showingTime) {
        // we need to capture the first point that occurred before the
        // start of the sequencer window
        if (showingPoints.length === 0) {
          showingPoints.push(lastPoint);
        }
        showingPoints.push(point);
      }
      nextPointKey = point.to;
      lastPoint = point;
    }
    if (showingPoints.length == 0) {
      showingPoints.push(lastPoint);
    }
    // add the lastPoint if there is one
    let lastKey = showingPoints[showingPoints.length - 1].to;
    if (lastKey != null) {
      showingPoints.push(propertyInfo[lastKey]);
    }
    return showingPoints;
  },
  // this will return the id of point before the time.
  // if this time is already there it will return the id of the
  // last instance of the time
  getInsertionPoint(propertyInfo, time) {
    let currentId = 'start';

    while (true) {
      let currentPoint = propertyInfo[currentId];
      if (currentPoint.time === time) {
        if (currentPoint.to === null || propertyInfo[currentPoint.to].time > time) {
          return currentId;
        }
      } else if (currentPoint.time > time) {
        // the point doesn't exist but we found the one after
        return currentPoint.from;

      } else if (currentPoint.to == null) {
        // reached end of list without finding it or anything after it
        return currentId;
      }
      currentId = currentPoint.to;
    }
  },
  insertNewPointAfter(propertyInfo, beforeId, newPoint) {
    let beforePoint = propertyInfo[beforeId];

    let newId = propertyInfo.nextId++;
    newPoint.id = newId;
    newPoint.from = beforeId;
    newPoint.to = beforePoint.to;
    beforePoint.to = newId;
    if (newPoint.to != null) {
      propertyInfo[newPoint.to].from = newId;
    }
    propertyInfo[newId] = newPoint;
    return newId
  },
  prepareSelectionsForMoving(propertyInfo, trackTime) {
    propertyInfo.selectedPoints.forEach((id) => {
      let point = propertyInfo[id];
      point.clickedTime = point.time;
      point.clickedValue = point.value;
      point.showingTime = trackTime;
      point.showingValue = propertyInfo.showingValue;
    })
  },
  getPointsInSelectionRange(propertyInfo, track) {
    let minTime = Math.min(track.startSelectingTime, track.currentSelectingTime);
    let maxTime = Math.max(track.startSelectingTime, track.currentSelectingTime);

    let currentId = 'start';
    let ids = [];
    while (true) {
      let currentPoint = propertyInfo[currentId];
      if (!currentPoint || currentPoint.time >= maxTime) {
        break;
      } else if (currentPoint.time >=  minTime && currentPoint.time < maxTime) {
        ids.push(currentPoint.id);
      }
      currentId = currentPoint.to;
    }
    return ids;
  },
  pointIsSelected(propertyInfo, pointId) {
    return propertyInfo.selectedPoints.indexOf(pointId) != -1;
  },
  deleteSelections(propertyInfo) {
    let selected = propertyInfo.selectedPoints;
    if (selected.length == 0) {
      return;
    }
    // id of point before deleted points
    let beforeId = null;
    // id of point after deleted points
    let afterId = null;
    let somethingWasDeleted = false;
    // this loop both returns the selected items that weren't deleted
    //  (only could be one item, the start item)
    // and it also deletes the items from the propertyInfo
    propertyInfo.selectedPoints = selected.filter((id) => {
      let point = propertyInfo[id];
      if (point.id == 'start') {
        beforeId = 'start';
        afterId = point.to;
        return true;
      } else if (beforeId == null) {
        beforeId = point.from;
      }
      afterId = point.to;
      delete propertyInfo[id];
      somethingWasDeleted = true;
      return false;
    });
    if (somethingWasDeleted) {
      propertyInfo[beforeId].to = afterId;
      if (afterId != null) {
        propertyInfo[afterId].from = beforeId;
      }
    }
  },
  getCurrentValueAndValueDirectlyAfter(propertyInfo, time) {
    let id = this.getInsertionPoint(propertyInfo, time);
    let point = propertyInfo[id];
    let nextPoint = propertyInfo[point.to];
    let currentValue = point.value * propertyInfo.unit;
    let nextValue = null;
    if (nextPoint) {
      // we are between two property points
      if (nextPoint.value == point.value || !propertyInfo.isGradient) {
        // straight horizontal line
        return {currentValue, nextValue: currentValue};
      }
      currentValue = this.getValueAtTimeBetweenTwoPoints(point, nextPoint, time) * propertyInfo.unit;
      nextValue = this.getValueAtTimeBetweenTwoPoints(point, nextPoint, time + 1) * propertyInfo.unit;
    } else {
      nextValue = point.value * propertyInfo.unit;
    }
    return { currentValue, nextValue };
  },
  getValueAtTimeBetweenTwoPoints(pt1, pt2, time) {
    let m = (pt1.value - pt2.value) / (pt1.time - pt2.time);
    let b = pt1.value - m * pt1.time;
    return m * time + b;
  },
  // this checks if there is more than just the initial
  // zero point. Necessary to know if we should be allowed to edit from a slider
  propertyHasMoreThanOnePoint(propertyInfo) {
    return propertyInfo['start'].to != null;
  }
}

export default PropertyHelper;
