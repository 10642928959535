import FMMultiSynth from './FMMultiSynth.js';
import GGain from './GGain.js';

import { DEVICES, PROPERTIES } from '../../constants.js';

let AudioConstructors = {
  [DEVICES.fmMultiSynth]: FMMultiSynth,
}

// a container for a chain of audio nodee

class AudioChain {

  constructor(context, receiver) {
    this._currentChainData = null;
    this._nodes = [];
    this._audioContext = context;

    this._outputGain = new GGain(this._audioContext);
    this._outputGain.setValueAtTime(0, this._audioContext.currentTime);
    this._outputGain.connect(receiver);
    this._receiver = receiver;
  }
  // chainData should be an array of info about instruments,
  // starting with the data of a source node
  setChainData(chainData) {
    if (!this._chainHasChanged(chainData)) {
      return;
    }
    this._deleteCurrentChain();
    // we use _currentChain to see if chainData we receive later is any different
    this._currentChainData = chainData.map((chainItemData) => chainItemData.id);
    this._nodes = [];

    let lastItem = null;
    chainData.forEach((chainItemData, index) => {
      let audioDevice = new AudioConstructors[chainItemData.type](this._audioContext);
      this._nodes.push(audioDevice);
      if (lastItem != null) {
        lastItem.connect(audioDevice);
      }
      lastItem = audioDevice;
    });
    lastItem.connect(this._outputGain);
  }
  _chainHasChanged(newChainData) {
    if (!this._currentChainData) {
      return true;
    }
    for (let i = 0; i < this._currentChainData; i++) {
      if (i >= newChainData.length) {
        return true;
      }
      if (this._currentChainData[i] != newChainData[i].id) {
        return true;
      }
    }
    return false;
  }
  _deleteCurrentChain() {
    if (!this._currentChainData) {
      return;
    }
    this._nodes.forEach((node, index) => {
      node.destroy();
    });
    this._currentChainData = null;
  }
  clearNotesAndStop() {
    // the first node is the instrument that plays the notes
    this._nodes[0].clearNotesAndStop();
  }
  setMusicData(musicData) {
    this._nodes[0].setNotes(musicData.playPosition, musicData.notes);

    let volume = musicData.trackProperties[PROPERTIES.volume];
    this._outputGain.setValueAtTime(volume.currentValue, this._audioContext.currentTime);
    this._outputGain.setTargetAtTime(volume.nextValue, this._audioContext.currentTime, musicData.loopTime);

    let pitchProperty = musicData.trackProperties[PROPERTIES.pitch];

    this._nodes.forEach((node, index) => {
      node.setDeviceProperties(musicData.instrumentProperties[index], pitchProperty, musicData.loopTime);
    });
    // nodes[0] is always the instrument
    this._nodes[0].play();
  }
}

export default AudioChain;
