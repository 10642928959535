import React from 'react';
import { connect } from 'react-redux';
import constants from '../constants.js';
import { VIEWMODES } from '../constants.js';
import MouseManager from '../MouseManager.js';
import {
  adjustSequencerWindow
} from '../actions/tracks.js';

class Seeker extends React.Component {
  constructor() {
    super();
    // used for dragging
    this._offsetTime = 0;
  }
  componentDidMount() {
    this._mouse = new MouseManager(
      this.seeker,
      this.didStartDrag.bind(this),
      this.didDrag.bind(this),
      this.didLetGo.bind(this),
      this.didWheel.bind(this),
    );
  }
  _getTimeForPoint(boxPoint) {
    let track = this.props.track;
    let rect = this.seeker.getBoundingClientRect();
    let trackPercent = boxPoint.x / rect.width;

    let time = Math.floor(constants.maxBars * constants.minNoteDivision * trackPercent);
    return { time }
  }
  didStartDrag(points) {
    let track = this.props.track;
    let { time } = this._getTimeForPoint(points.boxPoint);
    this._offsetTime = time - track.showingTime;
    return;
  }
  didDrag(points) {
    let track = this.props.track;
    let propertyInfo = this.props.propertyInfo;
    let { time } = this._getTimeForPoint(points.boxPoint);
    let newTime = time - this._offsetTime;
    let viewWindowTimeLength = constants.viewWidth / (track.magnification * constants.minNoteDivisionWidth);
    let maxLength = constants.maxBars * constants.minNoteDivision;
    if (newTime + viewWindowTimeLength >= maxLength) {
      newTime = maxLength - viewWindowTimeLength;
    }
    if (newTime < 0) {
      newTime = 0;
    }
    let showingValue = track.showingValue;
    if (track.viewMode == VIEWMODES.properties) {
      showingValue = propertyInfo.showingValue;
    }
    this.props.dispatch(adjustSequencerWindow(showingValue, null, newTime));
  }
  didLetGo() {
    let track = this.props.track;
    let propertyInfo = this.props.propertyInfo;
    if (!track) {
      return;
    }
    if (track.viewMode == VIEWMODES.properties) {
      this.props.dispatch(adjustSequencerWindow(null, propertyInfo.showingValue, track.showingTime, true));
    } else {
      this.props.dispatch(adjustSequencerWindow(track.showingValue, null, track.showingTime, true));
    }
  }
  didWheel() {

  }
  render()  {
    let track = this.props.track;
    let outerStyle = {
      width: constants.viewWidth + "px",
    };
    let maxLength = (constants.maxBars * constants.minNoteDivision);
    let minNotesOnScreen = constants.viewWidth / (track.magnification * constants.minNoteDivisionWidth);
    let windowStyle = {
      width: 100 * (minNotesOnScreen / maxLength) + "%",
      left: 100 * (track.showingTime / maxLength) + "%",
    };
    let songLength = this.props.endPosition - this.props.startPosition;
    let songStyle = {
      width: 100 * songLength  / maxLength + "%",
      left: 100 * this.props.startPosition / maxLength + "%",
    };
    let playStyle = {
      left: 100 * this.props.playPosition / maxLength + "%",
    };
    return (
      <div style={outerStyle} ref={(el) => this.seeker = el} className="seeker">
        <div style={songStyle} className="seekerViewSong"></div>
        <div style={windowStyle} className="seekerViewWindow"></div>
        <div style={playStyle} className="seekerViewPlayPosition"></div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    track: state.tracks.tracks[state.tracks.selectedTrack],
    propertyInfo: state.properties[state.properties.selectedProperty],
    endPosition: state.song.endPosition,
    startPosition: state.song.startPosition,
    playPosition: state.song.playPosition,
  }
}

export default connect(mapStateToProps)(Seeker);
