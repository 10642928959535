import GGain from './GGain.js';

const ADCtx = {
  init() {
    this._oldAudio = window.webkitAudioContext ? true : false;
    this._ctx = this._oldAudio ? new webkitAudioContext() : new AudioContext()
    this._masterOut = new GGain(this._ctx);
    this._masterOut.connect(this._ctx.destination);
  },
  getContext() {
    return this._ctx;
  },
  getMasterOut() {
    return this._masterOut;
  },
}

ADCtx.init();

export default ADCtx;
