import { PROPERTIES } from '../constants.js'
import { createProperty } from './properties.js';

export const initializeSongProperties = () => {
  return (dispatch, getState) => {
    dispatch(createProperty(PROPERTIES.bpm, 150, 1, 50, 300, true));
    let bpmId = getState().properties.lastPropertyId;
    dispatch(createProperty(PROPERTIES.volume, 5, .1, 0, 10, true));
    let volumeId = getState().properties.lastPropertyId;
    dispatch({
      type: 'initializeSongProperties',
      bpmId,
      volumeId,
    });
  }
}

export const advancePlayPosition = (position) => {
  return {
    type: "advancePlayPosition",
    position
  }
}

export const setBPM = (bpm) => {
  return {
    type: "setBPM",
    bpm
  }
}

export const togglePlaying = () => {
  return {
    type: "togglePlaying",
  }
}

export const startAdjustingPlayPosition = (position) => {
  return {
    type: 'startAdjustingPlayPosition',
    position,
  }
}
export const startAdjustingStartPosition = () => {
  return {
    type: 'startAdjustingStartPosition',
  }
}
export const startAdjustingEndPosition = () => {
  return {
    type: 'startAdjustingEndPosition',
  }
}
export const adjustPositionValue = (time) => {
  return {
    type: 'adjustPositionValue',
    time
  }
}
export const copySelection = (selectedNotes) => {
  return {
    type: 'copySelection',
    selectedNotes
  }
}

export const playingNote = (key) => {
  return {
    type: 'playingNote',
    key
  }
}
