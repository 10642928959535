import React from 'react';
import { connect } from 'react-redux';
import Slider from './Slider.jsx';
import { setSelectedTool } from '../actions/tools.js';
import { changeZoom } from '../actions/tracks.js';
import PropertyHelper from '../PropertyHelper.js';
import { PROPERTIES } from '../constants.js';
import {
  togglePlaying,
} from '../actions/song.js';
import {
  editPropertyFromUI
} from '../actions/properties.js';

class ToolMenu extends React.Component {
  constructor() {
    super();
  }
  clicked(tool) {
    this.props.setSelectedTool(tool);
  }
  _keyDown(e) {
    e.preventDefault();
  }
  _changedBPM(e) {
    this.props.dispatch(editPropertyFromUI(this.props.bpmProperty.id, e.target.value));
  }
  _changedVolume(e) {
    this.props.dispatch(editPropertyFromUI(this.props.masterVolumeProperty.id, e.target.value));
  }
  render() {
    let { tools, selectedTool, bpmProperty, masterVolumeProperty, playPosition } = this.props;

    let currentBPM = PropertyHelper.getCurrentValueAndValueDirectlyAfter(bpmProperty, playPosition).currentValue;
    let currentVolume = PropertyHelper.getCurrentValueAndValueDirectlyAfter(masterVolumeProperty, playPosition).currentValue / masterVolumeProperty.unit;
    currentVolume = Number(currentVolume).toPrecision(2);
    return (
      <div className="toolMenu">
        {
          tools.map((tool, index) => {
            return (<button onKeyDown={this._keyDown.bind(this)} key={index} onClick={() => this.clicked(tool)} className={`${tool} toolMenuTool ${tool == selectedTool ? 'selected' : '' }`}>

                    </button>)
          })
        }
        <button onKeyDown={this._keyDown.bind(this)} onClick={() => this.props.dispatch(changeZoom(-1))} className="minimize toolMenuTool"><sup>-</sup></button>
        <button onKeyDown={this._keyDown.bind(this)} onClick={() => this.props.dispatch(changeZoom(1))} className="maximize toolMenuTool">+</button>
        <button onKeyDown={this._keyDown.bind(this)} onClick={() => this.props.dispatch(togglePlaying())} className={`${this.props.playing ? "playing" : "paused"} toolMenuTool`}></button>
        <Slider label={currentBPM + " BPM"} onChange={() => {}} onInput={this._changedBPM.bind(this)} min={bpmProperty.min} max={bpmProperty.max} step={bpmProperty.unit} value={currentBPM} />
        <Slider label={currentVolume + " Volume"} onChange={() => {}} onInput={this._changedVolume.bind(this)} min={masterVolumeProperty.min} max={masterVolumeProperty.max} step={masterVolumeProperty.unit} value={currentVolume} />
      </div>
    )
  }
}

let mapStateToProps = (state, props) => {
  return {
    tools: state.tools.tools,
    selectedTool: state.tools.selectedTool,
    playing: state.song.playing,
    bpmProperty: state.properties[state.song.properties[PROPERTIES.bpm]],
    masterVolumeProperty: state.properties[state.song.properties[PROPERTIES.volume]],
    playPosition: state.song.playPosition,
  }
}
let mapDispatchToProps = (dispatch, props) => {
  return {
    dispatch,
    setSelectedTool: (tool) => dispatch(setSelectedTool(tool)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ToolMenu);
