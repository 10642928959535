import constants from '../constants.js';

let defaultState = {
  selectedTool: 'pencil',
  tools: [
    'pencil',
    'select',
    'hand',
    'slice',
    'speaker',
  ],
  firstClick: null,
  dragging: false,
  dragPosition: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'setSelectedTool': {
      return {
        ...state,
        selectedTool: action.selectedTool,
      };
    }
    default: {
      return state;
    }
  }
}
