import GAudioNode from './GAudioNode.js';
import GOscillator from './GOscillator.js';
import GGain from './GGain.js';
import { NOTES, PROPERTIES, WAVES } from '../../constants.js';

class FMMultiSynth extends GAudioNode {
  constructor(context) {
    super(context);
    this._outputNode = new GGain(this._ctx)
    this._outputNode.setValueAtTime(0, this._ctx.currentTime);

    this._slide = false;
    this._multi = false;
    //_currentNotes: just an array of notes that are supposed to be playing
    this._currentNotes = [];

    this._multiOscillators = [1,2,3,4,5].map((index) => {
      return this._createOscillator();
    })
    this._mono = this._createOscillator();
  }
  _createOscillator() {
    let carrier = new GOscillator(this._ctx);
    let mod = new GOscillator(this._ctx, true);
    mod.connect(carrier.getInputNodes()[0].frequency);
    carrier.connect(this._outputNode);
    return {
      carrier,
      mod,
    };
  }
  _updateOutputVolume(volumeValues, loopTime) {
    //this._outputNode.cancelScheduledValues(this._ctx.currentTime);
    this._outputNode.setValueAtTime(volumeValues.currentValue, this._ctx.currentTime);
    this._outputNode.setTargetAtTime(volumeValues.nextValue, this._ctx.currentTime, loopTime);
  }
  _updateMulti(multi) {
    if (this._multi === multi) { return; }
    this.clearNotesAndStop();
    this._multi = multi;
  }
  _getWaveString(wave) {
    switch (wave) {
      case WAVES.sine: {
        return 'sine';
      }
      case WAVES.square: {
        return 'square';
      }
      case WAVES.sawtooth: {
        return 'sawtooth';
      }
      case WAVES.triangle: {
        return 'triangle';
      }
      case WAVES.noise: {
        return 'noise'
      }
      default: {
        return 'sine';
      }
    }
  }
  setDeviceProperties(properties, pitch, loopTime) {
    this._slide = properties[PROPERTIES.slide].currentValue;
    this._slideTime = properties[PROPERTIES.slideTime].currentValue;
    this._attack = properties[PROPERTIES.attack].currentValue;
    this._decay = properties[PROPERTIES.decay].currentValue;
    this._sustain = properties[PROPERTIES.sustain].currentValue;
    this._release = properties[PROPERTIES.release].currentValue;
    this._wave = properties[PROPERTIES.wave].currentValue;
    this._volume = properties[PROPERTIES.volume];

    this._wave = this._getWaveString(this._wave);

    this._isFM = properties[PROPERTIES.isFM].currentValue;
    this._fmAttack = properties[PROPERTIES.fmAttack].currentValue;
    this._fmDecay = properties[PROPERTIES.fmDecay].currentValue;
    this._fmSustain = properties[PROPERTIES.fmSustain].currentValue;
    this._fmRelease = properties[PROPERTIES.fmRelease].currentValue;
    this._fmWave = properties[PROPERTIES.fmWave].currentValue;
    this._fmRatio = properties[PROPERTIES.fmRatio].currentValue;
    this._fmMax = properties[PROPERTIES.fmMax].currentValue;

    this._fmWave = this._getWaveString(this._fmWave);


    let multi = properties[PROPERTIES.multi].currentValue;
    this._updateMulti(multi)
    this._updateOutputVolume(this._volume, loopTime);

    this._multiOscillators.forEach((osc) => {
      osc.carrier.setWave(this._wave);
      osc.carrier.setSlide(false, 0);
      osc.carrier.setEnvelope(this._attack, this._decay, this._sustain, this._release);
      osc.carrier.setDetune(pitch.currentValue, pitch.nextValue, loopTime);

      osc.mod.setDisabled(!this._isFM);
      osc.mod.setWave(this._fmWave);
      osc.mod.setSlide(false, 0);
      osc.mod.setEnvelope(this._fmAttack, this._fmDecay, this._fmSustain, this._fmRelease);
      osc.mod.setDetune(pitch.currentValue, pitch.nextValue, loopTime);
      osc.mod.setMultiplier(this._fmRatio);
      osc.mod.setMaxAmp(this._fmMax);
    })
    if (this._mono) {
      let osc = this._mono;
      osc.carrier.setWave(this._wave);
      // multi oscillator never slides
      osc.carrier.setSlide(this._slide, this._slideTime);
      osc.carrier.setEnvelope(this._attack, this._decay, this._sustain, this._release);
      osc.carrier.setDetune(pitch.currentValue, pitch.nextValue, loopTime);

      osc.mod.setDisabled(!this._isFM);
      osc.mod.setWave(this._fmWave);
      osc.mod.setSlide(this._slide, this._slideTime);
      osc.mod.setEnvelope(this._fmAttack, this._fmDecay, this._fmSustain, this._fmRelease);
      osc.mod.setDetune(pitch.currentValue, pitch.nextValue, loopTime);
      osc.mod.setMultiplier(this._fmRatio);
      osc.mod.setMaxAmp(this._fmMax);
    }
  }
  setNotes(position, notesInfo) {
    this._currentNotes = this._currentNotes.filter((note) => {
      if (note.timeEnd <= position) {
        let osc = this._multiOscillators.find((osc) => osc.carrier.isPlayingKey(note.key));
        if (osc) {
          osc.carrier.release();
          osc.mod.release();
        }
        return false;
      }
      return true;
    });

    if (notesInfo.length == 0) {
      return;
    }
    this._currentNotes = notesInfo;
    // sorted by time first then key, both descending
    this._currentNotes.sort((a, b) => {
      let timeDiff = b.timeStart - a.timeStart;
      if (!timeDiff) {
        return b.key - a.key;
      } else {
        return timeDiff;
      }
    });
  }
  clearNotesAndStop() {
    this.stop();
    this._currentNotes = [];

    this._multiOscillators.forEach((osc) => {
      osc.carrier.clearNotes();
      osc.mod.clearNotes();
    });
    if (this._mono) {
      this._mono.carrier.clearNotes();
      this._mono.mod.clearNotes();
    }
  }
  play() {
    if (!this._multi) {
      if (this._currentNotes.length > 0) {
        this._mono.carrier.setNewNote(this._currentNotes[0]);
        this._mono.mod.setNewNote(this._currentNotes[0]);
      } else {
        this._mono.carrier.release();
        this._mono.mod.release();
      }
    } else {
      let subNotes = this._currentNotes.slice(0, this._multiOscillators.length);
      let usedOscillators = [];
      let unusedOscillators = [];
      this._multiOscillators.forEach((osc) => {
        for (let i = 0; i < subNotes.length; i++) {
          if (osc.carrier.isPlayingKey(subNotes[i].key)) {
            usedOscillators.push(osc);
            return;
          }
        }
        unusedOscillators.push(osc);
      });
      subNotes.forEach((note) => {
        let osc = usedOscillators.find((osc) => osc.carrier.isPlayingKey(note.key));
        if (osc) {
          osc.carrier.setNewNote(note);
          osc.mod.setNewNote(note);
        } else {
          if (unusedOscillators.length > 0) {
            osc = unusedOscillators.pop();
            osc.carrier.setNewNote(note);
            osc.mod.setNewNote(note);
            usedOscillators.push(osc);
          } else {
            osc = usedOscillators.shift();
            osc.carrier.setNewNote();
            osc.mod.setNewNote();
            usedOscillators.push(osc);
          }
        }
      })
    }
  }
  stop() {
    this._multiOscillators.forEach((osc) => {
      osc.carrier.release();
      osc.mod.release();
    });
    if (this._mono) {
      this._mono.carrier.release();
      this._mono.mod.release();
    }
  }
  _destroyInputs() {
    this._multiOscillators.forEach((osc) => {
      osc.carrier.disconnect();
      osc.carrier.destroy();
      osc.mod.disconnect();
      osc.mod.destroy();
    });
    this._multiOscillators = null;
    if (this._mono) {
      this._mono.carrier.disconnect();
      this._mono.carrier.destroy();
      this._mono.mod.disconnect();
      this._mono.mod.destroy();
      this._mono = null;
    }
  }
  getInputNodes() {
    return [...this._multiOscillators, this._mono];
  }
}


export default FMMultiSynth;
