import React from 'react';
import { connect } from 'react-redux';
import constants from '../constants.js';
import { POSITIONS } from '../constants.js';
import MouseManager from '../MouseManager.js';
import {
  startAdjustingPlayPosition,
  startAdjustingStartPosition,
  startAdjustingEndPosition,
  adjustPositionValue,
} from '../actions/song.js'

class TimeBar extends React.Component {
  constructor() {
    super();
    this.state = {
      // when we are dragging outside of the box, that is called a hyperdrag.
      // It should trigger a scroll when not zero
      hyperDragX: 0,
      hyperDragXSum: 0,
    }
  }
  componentDidMount() {
    this._mouse = new MouseManager(
      this.timeBar,
      this.didStartDrag.bind(this),
      this.didDrag.bind(this),
      this.didLetGo.bind(this),
      this.didWheel.bind(this),
    );
    this._checkForHyperDrag();
  }
  _checkForHyperDrag() {

  }
  _getTimeForCoordinateInTimeBar(boxPoint) {
    let track = this.props.track;

    let noteWidth = constants.minNoteDivisionWidth * track.magnification;
    let time = track.showingTime + Math.floor(boxPoint.x / noteWidth);
    let preciseTime = track.showingTime + boxPoint.x / noteWidth;
    return { time, preciseTime }
  }
  _getTimeMovement(movementVector) {
    let track = this.props.track;
    let magnification = track.magnification;

    let noteWidth = constants.minNoteDivisionWidth * magnification;
    let timeDiff =  Math.floor(-movementVector.x / noteWidth);
    return { timeDiff }
  }
  didStartDrag(points) {
    let track = this.props.track;
    let { time, preciseTime } = this._getTimeForCoordinateInTimeBar(points.boxPoint);

    if (Math.abs(this.props.startPosition + .5 - preciseTime) <= 1) {
      this.props.dispatch(startAdjustingStartPosition());
    } else if (Math.abs(this.props.endPosition + .5 - preciseTime) <= 1) {
      this.props.dispatch(startAdjustingEndPosition());
    } else {
      this.props.dispatch(startAdjustingPlayPosition(time));
    }
    return;
  }
  didDrag(points) {
    let { time, preciseTime } = this._getTimeForCoordinateInTimeBar(points.boxPoint);
    let {timeDiff } = this._getTimeMovement(points.movement);
    let track = this.props.track;
    let adjustingValue = this.props.adjustingValue;
    let adjustingStartPosition = this.props.adjustingStartPosition;

    if (time < 0) {
      time = 0;
    }

    if (points.hyperDragX != this.state.hyperDragX) {
      this.setState({ hyperDragX: points.hyperDragX});
      return;
    }
    if (adjustingValue == POSITIONS.playPosition) {
      this.props.dispatch(adjustPositionValue(time));
    } else if (adjustingValue == POSITIONS.startPosition) {
      if (time > this.props.endPosition - 4) {
        time = this.props.endPosition - 4;
      }
      this.props.dispatch(adjustPositionValue(time));
    } else if (adjustingValue == POSITIONS.endPosition) {
      if (time < this.props.startPosition + 4) {
        time = this.props.startPosition + 4;
      }
    }
    this.props.dispatch(adjustPositionValue(time));
  }
  didLetGo() {
    let track = this.props.track;
    this.setState({
      hyperDragX: 0,
      hyperDragXSum: 0,
    });
  }
  didWheel() {

  }
  _adjustValue(timeDiff) {
    let track = this.props.track;
    let notes = {};
    track.selectedNotes.forEach((noteId) => {
      let note = track.notes[noteId];
      let timeEnd = note.timeEnd;
      let timeStart = note.clickedTime - timeDiff + this.state.hyperDragXSum;
      if (timeStart > timeEnd - 1) { timeStart = timeEnd - 1 };
      notes[noteId] = { key: note.key, timeStart, timeEnd };
    })
    this.props.dispatch(editNotes(notes));
  }
  render() {
    let track = this.props.track;
    let showingTime = track.showingTime;
    let magnification = track.magnification;
    let offset = -(showingTime % constants.minNoteDivision) * constants.minNoteDivisionWidth * magnification;
    let barWidth = constants.minNoteDivision * constants.minNoteDivisionWidth * magnification;
    let bars = [];
    let barsOnScreen = Math.floor(constants.viewWidth / barWidth) + 1;
    // we need the extra one for bleed
    let currentBarNumber = Math.floor(showingTime / constants.minNoteDivision);
    for (let i = 0; i < barsOnScreen + 1; i++) {
      let playBar = null;
      let adjPlayPosition = this.props.playPosition / constants.minNoteDivision;
      if (adjPlayPosition >= currentBarNumber && adjPlayPosition < currentBarNumber + 1) {
        let percent = (adjPlayPosition - currentBarNumber) * 100 + '%';
        playBar = <div style={{left: percent}} className="timeBarPlayPosition"></div>
      }
      let endBar = null;
      let adjEndPosition = this.props.endPosition / constants.minNoteDivision;
      if (adjEndPosition >= currentBarNumber && adjEndPosition < currentBarNumber + 1) {
        let percent = 100 - ((adjEndPosition -  currentBarNumber) * 100) + '%';
        endBar = <div style={{width: percent}} className="timeBarEndPosition"></div>
      } else if (adjEndPosition < currentBarNumber) {
        endBar = <div style={{width: "100%"}} className="timeBarEndPosition"></div>
      }
      let startBar = null;
      let adjStartPosition = this.props.startPosition / constants.minNoteDivision;
      if (adjStartPosition >= currentBarNumber && adjStartPosition < currentBarNumber + 1) {
        let percent = (adjStartPosition -  currentBarNumber) * 100 + '%';
        startBar = <div style={{width: percent}} className="timeBarStartPosition"></div>
      } else if (adjStartPosition > currentBarNumber) {
        startBar = <div style={{width: "100%"}} className="timeBarStartPosition"></div>
      }

      let wrapperStyle = {
        width: `${barWidth + (i == 0 ? offset : 0 )}px`
      };
      bars.push(
        <div key={i} style={wrapperStyle} className="timeBarSliceWrapper">
          <div style={{width: `${barWidth}px`}} className="timeBarSlice">
            { currentBarNumber++ }
            { playBar }
            { endBar }
            { startBar }
          </div>
        </div>
      )
    }

    return (
      <div ref={(el) => this.timeBar = el} style={{width: constants.viewWidth + "px"}} className="timeBar">
        { bars }
      </div>
    );
  }
}

const mapStateToProps = (state, newProps) => {
  return {
    track: state.tracks.tracks[state.tracks.selectedTrack],
    playPosition: state.song.playPosition,
    startPosition: state.song.startPosition,
    endPosition: state.song.endPosition,
    adjustingStartPosition: state.song.adjustingStartPosition,
    adjustingValue: state.song.adjustingValue
  };
}
const mapDispatchToProps = (dispatch, props) => {
  return {
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeBar);
