import React from 'react';
import { NavLink } from 'react-router-dom';
import constants from '../constants.js';
import { NOTES } from '../constants.js';
import { ACTIONS } from '../constants.js';
import { connect } from 'react-redux';
import {
  setMagnification,
  beginAdjustingSequencerWindow,
  adjustSequencerWindow,
  createNote,
  editNotes,
  startSelectingPoint,
  setCurrentSelectingPoint,
  setSelectedNotes,
  startExpandingSelections,
  startExpandingNewSelection,
  startMovingNotes,
  startMovingNewlySelectedNote,
  setCurrentAction,
  cutNote,
  deleteNotes,
  undo,
} from '../actions/tracks.js';
import { setPlaySong, playingNote } from '../actions/song.js';
import MouseManager from '../MouseManager.js';
import Grid from './Grid.jsx';

class Sequencer extends React.Component {
  constructor() {
    super();
    this.state = {
      // when we are dragging outside of the box, that is called a hyperdrag.
      // It should trigger a scroll when not zero and the right tool is selected
      hyperDragX: 0,
      hyperDragY: 0,
      hyperDragXSum: 0,
      hyperDragYSum: 0,

      showResizeCursor: false,
    }
  }
  componentDidMount() {
    this._mouse = new MouseManager(
      this.sequencer,
      this.didStartDrag.bind(this),
      this.didDrag.bind(this),
      this.didLetGo.bind(this),
      this.didWheel.bind(this),
      this.didMove.bind(this)
    );
    this._checkForHyperDrag();
  }
  _checkForHyperDrag() {
    if (this.state.hyperDragX != 0 || this.state.hyperDragY != 0) {
      let track = this.props.track;
      let multiX = Math.abs(Math.round(this.state.hyperDragX / 5)) % 20;
      let multiY = Math.abs(Math.round(this.state.hyperDragY / 5)) % 20;
      let timeDiff = this.state.hyperDragX < 0 ? -5 * multiX : (this.state.hyperDragX > 0 ? 1 * multiX : 0);
      let keyDiff = this.state.hyperDragY < 0 ? 1 * multiY : (this.state.hyperDragY > 0 ? -1 * multiY: 0);

      let newTime = track.lastShowingTime + timeDiff;
      if (newTime < 0) { newTime = 0 };
      let newKey = track.lastShowingValue + keyDiff;
      //if (newKey < 0) { newKey = 0 };

      if (newTime != track.lastShowingTime || newKey != track.lastShowingValue) {
        let actualTimeDiff = newTime - track.lastShowingTime;
        let actualKeyDiff = newKey - track.lastShowingValue;
        this.setState((state) => ({
          hyperDragXSum: state.hyperDragXSum + actualTimeDiff,
          hyperDragYSum: state.hyperDragYSum + actualKeyDiff,
        }));
        this.props.dispatch(adjustSequencerWindow(newKey, null, newTime, true));
        if (track.currentAction == ACTIONS.moving) {
          this._changeNotePlacement(actualKeyDiff, actualTimeDiff)
        }
      }
    }
    this._timeout = setTimeout(this._checkForHyperDrag.bind(this), 300);
  }
  _changeNotePlacement(keyDiff, timeDiff) {
    let track = this.props.track;
    let notes = {};
    track.selectedNotes.forEach((noteId) => {
      let note = track.notes[noteId];
      let length = note.timeEnd - note.timeStart;
      let newKey = note.clickedKey - keyDiff + this.state.hyperDragYSum;
      let newStart = note.clickedTime - timeDiff + this.state.hyperDragXSum;
      let newEnd = newStart + length;
      notes[noteId] = { key: newKey, timeStart: newStart, timeEnd: newEnd };
    });
    this.props.dispatch(editNotes(notes));
  }
  _changeNoteStartTime(timeDiff) {
    let track = this.props.track;
    let notes = {};
    track.selectedNotes.forEach((noteId) => {
      let note = track.notes[noteId];
      let timeEnd = note.timeEnd;
      let timeStart = note.clickedTime - timeDiff + this.state.hyperDragXSum;
      if (timeStart > timeEnd - 1) { timeStart = timeEnd - 1 };
      notes[noteId] = { key: note.key, timeStart, timeEnd };
    })
    this.props.dispatch(editNotes(notes));
  }
  _changeNoteEndTime(timeDiff) {
    let track = this.props.track;
    let notes = {};
    track.selectedNotes.forEach((noteId) => {
      let note = track.notes[noteId];
      let timeStart = note.timeStart;
      let timeEnd = note.clickedTime - timeDiff + this.state.hyperDragXSum;
      if (timeEnd < timeStart + 1) { timeEnd = timeStart + 1 };
      notes[noteId] = {key: note.key, timeStart, timeEnd };
    })
    this.props.dispatch(editNotes(notes))
  }
  _moveWindow(keyDiff, timeDiff) {
    let track = this.props.track;
    let newTime = track.lastShowingTime + timeDiff;
    if (newTime < 0) { newTime = 0 };
    let newKey = track.lastShowingValue + keyDiff;
    //if (newKey < 0) { newKey = 0 };
    this.props.dispatch(adjustSequencerWindow(newKey, null, newTime));
  }
  _getTimeAndKeyForCoordinateInSequencer(boxPoint) {
    let track = this.props.track;
    let height = constants.keysOnScreen * constants.noteHeight;

    let yDiff = height - boxPoint.y;
    let numberOfNotesFromBottom = Math.floor(yDiff / constants.noteHeight);
    let key = track.showingValue + numberOfNotesFromBottom;

    let noteWidth = constants.minNoteDivisionWidth * track.magnification;
    let time = track.showingTime + Math.floor(boxPoint.x / noteWidth);
    let preciseTime = track.showingTime + boxPoint.x / noteWidth;
    return { time, key, preciseTime }
  }
  _getTimeAndKeyMovement(movementVector) {
    let track = this.props.track;
    let magnification = track.magnification;

    let height = constants.keysOnScreen * constants.noteHeight;

    let keyDiff = Math.floor(movementVector.y / constants.noteHeight);

    let noteWidth = constants.minNoteDivisionWidth * magnification;
    let timeDiff =  Math.floor(-movementVector.x / noteWidth);
    return { timeDiff, keyDiff }
  }
  // this isn't called when dragging
  didMove(boxPoint) {
    if (this.props.tool != 'select') {
      return;
    }
    let track = this.props.track;
    let { time, key, preciseTime } = this._getTimeAndKeyForCoordinateInSequencer(boxPoint);
    // find which notes in this section of track are the key we pressed
    let ids = Object.keys((track.points[time] || {})).filter((id) => {
      return track.notes[id].key == key;
    });
    if (ids.length == 0) {
      // we did not click a note
      this.setState({ showResizeCursor: false });
      return;
    }
    // get the note we clicked that has most recent startTime
    // (more than one note with same key can occur simultaneously)
    let topId = ids.sort((a, b) => {
      return track.notes[a].timeStart - track.notes[b].timeStart;
    }).pop();
    let note = track.notes[topId];

    let resizeThreshold = .4;
    if ((note.timeEnd - preciseTime) < resizeThreshold) {
      // we hovered over right edge of note and should change cursor
      this.setState({ showResizeCursor: true });
    } else if (preciseTime - note.timeStart <= resizeThreshold) {
      // we hovered over left edge of note and should change cursor
      this.setState({ showResizeCursor: true });
    } else {
      this.setState({ showResizeCursor: false });
    }
  }
  didStartDrag(points) {

    this.setState({ showResizeCursor: false });

    let track = this.props.track;
    let { time, key, preciseTime } = this._getTimeAndKeyForCoordinateInSequencer(points.boxPoint);

    switch (this.props.tool) {
      case 'hand': {
        this.props.dispatch(beginAdjustingSequencerWindow(track.showingValue, track.showingTime));
        break;
      }
      case 'pencil': {
        this.props.dispatch(createNote(key, time, time + 1));
        break;
      }
      case 'slice': {
        // find which notes in this section of track are the key we pressed
        let ids = Object.keys((track.points[time] || {})).filter((id) => {
          return track.notes[id].key == key;
        });
        if (ids.length == 0) {
          // we did not click a note
          return;
        }
        // get the note we clicked that has most recent startTime
        // (more than one note with same key can occur simultaneously)
        let noteId = ids.sort((a, b) => {
          return track.notes[a].timeStart - track.notes[b].timeStart;
        }).pop();
        let note = track.notes[noteId];

        if (time == note.timeStart) {
          // we can't slice the beginning of a note
          return;
        }
        this.props.dispatch(cutNote(noteId, time))
        break;
      }
      case 'select': {
        // find which notes in this section of track are the key we pressed
        let ids = Object.keys((track.points[time] || {})).filter((id) => {
          return track.notes[id].key == key;
        });
        if (ids.length == 0) {
          // we did not click a note
          this.props.dispatch(startSelectingPoint(key, time))
          return;
        }
        // get the note we clicked that has most recent startTime
        // (more than one note with same key can occur simultaneously)
        let topId = ids.sort((a, b) => {
          return track.notes[a].timeStart - track.notes[b].timeStart;
        }).pop();
        let note = track.notes[topId];

        let resizeThreshold = .4;
        if ((note.timeEnd - preciseTime) < resizeThreshold) {
          // we clicked right edge of note and should expand
          if (!note.selected) {
            this.props.dispatch(startExpandingNewSelection(topId))
          } else {
            this.props.dispatch(startExpandingSelections());
          }
        } else if (preciseTime - note.timeStart <= resizeThreshold) {
          // we clicked left edge of note and should expand
          if (!note.selected) {
            this.props.dispatch(startExpandingNewSelection(topId, true))
          } else {
            this.props.dispatch(startExpandingSelections(true));
          }
        } else {
          // we clicked center of note and should start moving it
          if (!note.selected) {
            this.props.dispatch(startMovingNewlySelectedNote(topId, true))
          } else {
            this.props.dispatch(startMovingNotes(true));
          }
        }
        break;
      }
      case 'speaker': {
        this.props.dispatch(playingNote(key));
        break;
      }
      default: {
        return true;
      }
    }
  }
  didDrag(points) {
    this.setState({ showResizeCursor: false });

    let { time, key, preciseTime } = this._getTimeAndKeyForCoordinateInSequencer(points.boxPoint);
    let {timeDiff, keyDiff} = this._getTimeAndKeyMovement(points.movement);
    let track = this.props.track;

    switch (this.props.tool) {
      case 'hand': {
        this._moveWindow(keyDiff, timeDiff);
        break;
      }
      case 'pencil': {
        if (points.hyperDragX != this.state.hyperDragX || points.hyperDragY != this.state.hyperDragY) {
          this.setState({ hyperDragX: points.hyperDragX, hyperDragY: points.hyperDragY});
        } else {
          this._changeNoteEndTime(timeDiff);
        }
        break;
      }
      case 'select': {
        if (points.hyperDragX != this.state.hyperDragX || points.hyperDragY != this.state.hyperDragY) {
          this.setState({ hyperDragX: points.hyperDragX, hyperDragY: points.hyperDragY});
        } else if (track.currentAction == ACTIONS.expandRight) {
          this._changeNoteEndTime(timeDiff);
        } else if (track.currentAction == ACTIONS.expandLeft) {
          this._changeNoteStartTime(timeDiff);
        } else if (track.currentAction == ACTIONS.selecting) {
          this.props.dispatch(setCurrentSelectingPoint(key, time));
        } else if (track.currentAction == ACTIONS.moving) {
          this._changeNotePlacement(keyDiff, timeDiff);
        }
        break;
      }
      case 'speaker': {
        this.props.dispatch(playingNote(key));
        return;
        break;
      }
      default: {
        return true;
      }
    }
  }
  didLetGo(points) {
    let track = this.props.track;
    this.setState({
      hyperDragY: 0,
      hyperDragX: 0,
      hyperDragXSum: 0,
      hyperDragYSum: 0,
      moving: false,
    });
    switch (this.props.tool) {
      case 'hand': {
        // just sets the lastShowingTime and lastShowingValue to the current values
        this.props.dispatch(adjustSequencerWindow(track.showingValue, null, track.showingTime, true));
        break;
      }
      case 'select': {
        if (track.currentAction == ACTIONS.selecting) {
          let selectedNotes = this.getSelectedNotes(track);
          this.props.dispatch(setSelectedNotes(selectedNotes));
        }
        break;
      }
      case 'speaker': {
        this.props.dispatch(playingNote(null));
        return;
        break;
      }
    }
    this.props.dispatch(setCurrentAction(null));
  }
  didWheel(points) {
    let track = this.props.track;
    let {timeDiff, keyDiff} = this._getTimeAndKeyMovement(points.movement);
    let newTime = track.showingTime + timeDiff;
    if (newTime < 0) { newTime = 0 };
    let newKey = track.showingValue + keyDiff;
    //if (newKey < 0) { newKey = 0 };
    this.props.dispatch(adjustSequencerWindow(newKey, null, newTime, true));
  }
  getSelectedNotes(track) {
    let { showingValue } = track;

    let leftMost = Math.min(track.startSelectingTime, track.currentSelectingTime);
    let bottomMost = Math.min(track.startSelectingValue, track.currentSelectingValue);
    let width = Math.abs(track.currentSelectingTime - track.startSelectingTime);
    let height =  Math.abs(track.currentSelectingValue - track.startSelectingValue) + 1;

    let keyInRange = (key) => key >= bottomMost && key < bottomMost + height;

    let alreadyAdded = {};
    let selectedNotes = [];
    for (let i = leftMost; i < leftMost + width; i++) {
      if (track.points[i]) {
        let noteIdsForPoint = Object.keys(track.points[i]);
        noteIdsForPoint.forEach((id) => {
          if (!alreadyAdded[id] && keyInRange(track.notes[id].key)) {
            alreadyAdded[id] = true;
            selectedNotes.push(id);
          }
        })
      }
    }
    return selectedNotes;
  }
  componentWillUnmount() {
    this._mouse.destroy();
    clearTimeout(this._timeout);
  }
  onClick(e) {

  }
  getCursor() {
    if (this.state.showResizeCursor) {
      return 'ew-resize';
    }
    let cursors = {
      'pencil': 'crosshair',
      'hand': 'grab',
      'slice': 'crosshair',
      'select': 'default'
    }
    return cursors[this.props.tool];
  }
  render() {
    let cursor = this.getCursor();
    let track = this.props.track;
    let magnification = track.magnification;
    let showingTime = track.showingTime;
    let showingValue = track.showingValue;
    let height = constants.keysOnScreen * constants.noteHeight;
    let width = constants.viewWidth;
    let lines = [];
    for (let i = showingValue; i < showingValue + constants.keysOnScreen; i++) {
      lines.push(<Line key={i} track={track} keyNumber={i} magnification={magnification} startPoint={showingTime}/>);
    }
    return (
      <div tabIndex="0"
           ref={(el) => this.sequencer = el}
           style={{ height: height + 'px', width: width + 'px', cursor }}
           className="sequencerWrapper" >
        <Grid magnification={magnification} showingTime={showingTime} />
        <div className="sequencerLines">
          { lines.reverse() }
        </div>
        <Notes track={track} />
      </div>
    );
  }
}

const mapStateToProps = (state, newProps) => {
  return {
    selectedTrack: state.tracks.selectedTrack,
    track: state.tracks.tracks[state.tracks.selectedTrack],
    tool: state.tools.selectedTool,
    playing: state.song.playing
  };
}
const mapDispatchToProps = (dispatch, props) => {
  return {
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sequencer);


const Line = ({ track, magnification, keyNumber, startPoint }) => {
  let barWidth = constants.minNoteDivision * constants.minNoteDivisionWidth * magnification;
  let barsOnScreen = Math.floor(constants.viewWidth / barWidth) + 1;

  let blackKeys = [1, 3, 6, 8, 10];
  // we need to check so we don't divide by zero
  let drawStart = startPoint;
  let drawEnd = drawStart + constants.minNoteDivision * barsOnScreen;

  let height = constants.noteHeight;
  let keyBase = keyNumber % 12;
  keyBase = keyBase < 0 ? 12 + keyBase : keyBase;
  let blackKey = blackKeys.indexOf(keyBase) != -1 ? "blackKey" : "";

  return (
    <div style={{height: `${height}px`}} className={`sequencerLine ${blackKey}`}>
      { NOTES[keyBase][0] + Math.floor(keyNumber / 12) }
    </div>
  );
}

const Notes = ({ track }) => {

  let magnification = track.magnification;
  let showingTime = track.showingTime;
  let showingValue = track.showingValue;
  let height = constants.keysOnScreen * constants.noteHeight;
  let width = constants.viewWidth;

  let addedNotes = {};
  let pointInfo = track.points;
  let noteElements = [];
  let keyInRange = (key) => key >= showingValue && key < showingValue + constants.keysOnScreen;

  let barWidth = constants.minNoteDivision * constants.minNoteDivisionWidth * magnification;
  let barsOnScreen = Math.floor(constants.viewWidth / barWidth) + 1;

  let drawEnd = showingTime + constants.minNoteDivision * barsOnScreen;

  let selectionBox = null;
  if (track.currentAction == ACTIONS.selecting) {
    let style = {
      left: (Math.min(track.startSelectingTime, track.currentSelectingTime) - showingTime) * constants.minNoteDivisionWidth * magnification + 'px',
      bottom: (Math.min(track.startSelectingValue, track.currentSelectingValue) - showingValue) * constants.noteHeight + 'px',
      width: Math.abs(track.currentSelectingTime - track.startSelectingTime) * constants.minNoteDivisionWidth * magnification + 'px',
      height: Math.abs(track.currentSelectingValue - track.startSelectingValue) * constants.noteHeight + constants.noteHeight + 'px',
    };
    selectionBox = (
      <div className="sequencerSelectionBox"
           style={style}>
      </div>
    );
  }

  for (let i = showingTime; i < drawEnd; i++) {
    if (pointInfo[i]) {
      let noteIdsForPoint = Object.keys(pointInfo[i]);
      noteIdsForPoint.forEach((id) => {
        if (!addedNotes[id] && keyInRange(track.notes[id].key)) {
          addedNotes[id] = true;
          let note = track.notes[id];
          let noteLength = note.timeEnd - note.timeStart;
          //noteElements.push(<Note key={id} id={id} selected={noteInfo.selected} start={noteInfo.timeStart} length={noteLength} magnification={magnification} parentStart={drawStart} />)
          noteElements.push(
            <div className={`sequencerNote ${note.selected ? 'selected' : ''}`}
                 key={id}
                 style={{
                   left: (note.timeStart - showingTime) * constants.minNoteDivisionWidth * magnification + 'px',
                   width: noteLength * constants.minNoteDivisionWidth * magnification + 'px',
                   height: constants.noteHeight + 'px',
                   bottom: constants.noteHeight * (note.key - showingValue) + 'px'
                 }}>
            </div>
          );
        }
      })
    }
  }
  return (
    <div className="sequencerNotes">
      { noteElements }
      { selectionBox }
    </div>
  )
}
