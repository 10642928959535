import React from 'react';
import Slider from './Slider.jsx';

const NumberTuner = ({label, num, max, onInput}) => {
  let vals = {
    seconds:  num % 1,
    tenths: Math.floor((num % .1) * 10) / 10,
    hunds: Math.floor((num % .01) * 100) / 100,
  }


  let onInp = (type, valueSlices) => {
    return (e) => {
      let value = e.target.value;
      switch (type) {
        case 'seconds': {
          onInput(+value + vals.tenths + vals.hunds);
          break;
        }
        case 'tenths': {
          onInput(+value  + vals.seconds + vals.hunds);
          break;
        }
        case 'hunds': {
          onInput(+value  + vals.seconds + vals.tenths);
          break;
        }
      }
    }
  }

  return (
    <div className="numberTuner">
      <Slider label={`${label} Seconds ${vals.seconds}`} onChange={() => {}} onInput={onInp('seconds', vals)} min={0} max={max} step={1} value={+vals.seconds} />
      <Slider label={`${label} Tenths ${vals.tenths}`} onChange={() => {}} onInput={onInp('tenths', vals)} min={0} max={.9} step={.1} value={+vals.tenths} />
      <Slider label={`${label} Hunds ${vals.hunds}`} onChange={() => {}} onInput={onInp('hunds', vals)} min={0} max={.09} step={.01} value={+vals.hunds} />
    </div>
  )
}

export default NumberTuner;
