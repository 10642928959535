import React from 'react';
import constants from '../constants';

const Grid = ({ magnification, showingTime }) => {
  let gridOffset = -(showingTime % constants.minNoteDivision) * constants.minNoteDivisionWidth * magnification;
  let height = constants.keysOnScreen * constants.noteHeight;
  let barWidth = constants.minNoteDivision * constants.minNoteDivisionWidth * magnification;
  let bars = [];
  let barsOnScreen = Math.floor(constants.viewWidth / barWidth) + 1;
  // we need the extra one for bleed
  for (let i = 0; i < barsOnScreen + 1; i++) {
    let style = {
      height: `${height}px`,
      width: `${barWidth + (i == 0 ? gridOffset : 0 )}px`
    };
    bars.push(
      <div key={i} style={style} className="barGridWrapper">
        <div style={{width: `${barWidth}px`}} className="barGrid">

        </div>
      </div>
    );
  }
  return (
    <div className="grid">
      { bars }
    </div>
  );
}

export default Grid;
