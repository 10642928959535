import constants from '../constants.js';
import { ACTIONS, VIEWMODES, PROPERTIES } from '../constants.js';
import PropertyHelper from '../PropertyHelper.js';


// will be an object where each key is the id of a property except for the two keys in the default
let defaultState = {
  lastPropertyId: -Number.MAX_SAFE_INTEGER,
  currentAction: null,
  selectedProperty: null,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'createProperty': {
      let newState = {
        ...state,
      }
      let id = ++newState.lastPropertyId;
      newState[id] = PropertyHelper.createNewProperty(id, action.name, action.defaultValue, action.unit, action.min, action.max, action.isGradient);
      return newState;
    }
    case 'setSelectedProperty': {
      let newState = {
        ...state
      }
      newState.selectedProperty = action.id;
      return newState;
    }
    case 'setCurrentAction': {
      let { newState } = PropertyHelper.getCopiedState(state, state.selectedProperty);
      newState.currentAction = action.currentAction;
      return newState;
    }
    case 'setSelectedPoints': {
      let { newState, propertyInfo } = PropertyHelper.getCopiedState(state, state.selectedProperty);
      propertyInfo.selectedPoints = action.ids;
      return newState;
    }
    case 'adjustSequencerWindow': {
      let { newState, propertyInfo } = PropertyHelper.getCopiedState(state, state.selectedProperty);
      if (action.propertyValue !== null) {
        propertyInfo.showingValue = action.propertyValue;
      }
      return newState;
    }
    // creats a new point
    case 'createPropertyValue': {
      let { newState, propertyInfo } = PropertyHelper.getCopiedState(state, state.selectedProperty);
      let insertionPointId = PropertyHelper.getInsertionPoint(propertyInfo, action.time);
      if (propertyInfo.max != null) {
        action.value = action.value > propertyInfo.max ? propertyInfo.max : action.value;
      }
      if (propertyInfo.min != null) {
        action.value = action.value < propertyInfo.min ? propertyInfo.min : action.value;
      }
      let point = {
        id: null,
        time: action.time,
        value: action.value,
        from: null, // we don't know yet
        to: null, // we don't know yet
      };
      newState.currentAction = ACTIONS.moving;
      let newId = PropertyHelper.insertNewPointAfter(propertyInfo, insertionPointId, point);
      propertyInfo.selectedPoints = [newId];
      PropertyHelper.prepareSelectionsForMoving(propertyInfo, action.trackTime);
      return newState;
    }
    case 'startMovingPropertyPoints': {
      let { newState, propertyInfo } = PropertyHelper.getCopiedState(state, state.selectedProperty);
      PropertyHelper.prepareSelectionsForMoving(propertyInfo, action.trackTime);
      newState.currentAction = ACTIONS.moving;
      return newState;
    }
    case 'startMovingNewlySelectedPropertyPoint': {
      let { newState, propertyInfo } = PropertyHelper.getCopiedState(state, state.selectedProperty);
      propertyInfo.selectedPoints = [action.selectionId];
      PropertyHelper.prepareSelectionsForMoving(propertyInfo, action.trackTime);
      newState.currentAction = ACTIONS.moving;
      return newState;
    }
    case 'deleteSelectedPropertyPoints': {
      let { newState, propertyInfo } = PropertyHelper.getCopiedState(state, state.selectedProperty);
      PropertyHelper.deleteSelections(propertyInfo);
      return newState;
    }
    case 'editPropertyPoints': {
      let { newState, propertyInfo } = PropertyHelper.getCopiedState(state, action.propertyId);
      action.points.forEach((point) => {
        if (propertyInfo.max != null) {
          point.value = point.value > propertyInfo.max ? propertyInfo.max : point.value;
        }
        if (propertyInfo.min != null) {
          point.value = point.value < propertyInfo.min ? propertyInfo.min : point.value;
        }
        propertyInfo[point.id] = point;
      })
      return newState;
    }
    // technically editing from the property view is still editing from the UI
    // but we don't include that. This basically means 'editing from the conventient ui' like from a slider
    // or on/off button
    case 'editPropertyFromUI': {
      if (PropertyHelper.propertyHasMoreThanOnePoint(state[action.propertyId])) {
        // we can't edit from slider if the user has created more points
        return state;
      }
      let { newState, propertyInfo } = PropertyHelper.getCopiedState(state, action.propertyId);
      let value = action.value;
      if (propertyInfo.max != null) {
        value = value > propertyInfo.max ? propertyInfo.max : value;
      }
      if (propertyInfo.min != null) {
        value = value < propertyInfo.min ? propertyInfo.min : value;
      }
      propertyInfo['start'].value = value;
      return newState;
    }
    default: {
      return state;
    }
  }
}
