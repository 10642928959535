import React from 'react';

const Slider = ({onChange, onInput, min, max, step, value, label}) => {
  let percent = (value - min) / (max - min) * 100;
  let style = {
    left: percent + '%'
  };
  let labelComponent = null;
  if (label) {
    labelComponent = (
      <div className="gorahSliderLabel">
        {label}
      </div>
    )
  }
  return (
    <div className="gorahSlider">
      <div className="gorahSliderLabelHolder">
       { labelComponent }
      </div>
      <div className="gorahSliderInputHolder">
        <input type="range" min={min} type="range" max={max} step={step} value={value} onChange={onChange} onInput={onInput} />
        <div className="gorahSliderMask">
          <div className="gorahSliderBar">
            <div style={style} className="gorahSliderHandlePoint">
              <div className="gorahSliderHandle"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Slider;
