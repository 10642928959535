const MAX_BARS_VIEWABLE = 8;
const VIEWABLE_KEYS = 36; // 3 octaves
const MIN_NOTE_DIVISION_WIDTH = 6;
const MIN_NOTE_DIVISION = 16;
const NOTE_HEIGHT = 12;

export default {
  maxBars: 80,
  keysOnScreen: VIEWABLE_KEYS,
  minNoteDivisionWidth: MIN_NOTE_DIVISION_WIDTH,
  noteHeight: NOTE_HEIGHT,
  minNoteDivision: MIN_NOTE_DIVISION,
  // this width affects performance
  viewWidth: 900,
}

export const VIEWMODES = {
  sequencer: 'sequencer',
  properties: 'properties'
};
export const PROPERTIES = {
  pitch: 'pitch',
  volume: 'volume',
  bpm: 'bpm',
  attack: 'attack',
  decay: 'decay',
  sustain: 'sustain',
  release: 'release',
  slide: 'slide',
  slideTime: 'slideTime',
  wave: 'wave',
  multi: 'multi',
  isFM: 'isFM',
  fmAttack: 'fmAttack',
  fmDecay: 'fmDecay',
  fmSustain: 'fmSustain',
  fmRelease: 'fmRelease',
  fmWave: 'fmWave,',
  fmRatio: 'fmRatio',
  fmMax: 'fmMax',
};

export const NOTES = {
  0: ['C', 16.35160],
  1: ['C#', 17.32391],
  2: ['D', 18.35405],
  3: ['D#', 19.44544],
  4: ['E', 20.60172],
  5: ['F', 21.82676],
  6: ['F#', 23.12465],
  7: ['G', 24.49971],
  8: ['G#', 25.95654],
  9: ['A', 27.50000	],
  10: ['A#', 29.13524],
  11: ['B', 30.86771]
}

export const ACTIONS = {
  selecting: 'selecting',
  expandRight: 'expandRight',
  expandLeft: 'expandLeft',
  moving: 'moving',
}

export const POSITIONS = {
  startPosition: 'startPosition',
  playPosition: 'playPosition',
  endPosition: 'endPosition',
}

export const WAVES = {
  sine: 0,
  square: 1,
  sawtooth: 2,
  triangle: 3,
  noise: 4,
}

export const DEVICES = {
  fmMultiSynth: "FMMultiSynth",
  synth: 'synth',
}
