let defaultState = {
  isMobile: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'setIsMobile': {
      let newState = {
        ...state,
        isMobile: action.isMobile
      }
      return newState;
    }
    default: {
      return state;
    }
  }
}
