import React from 'react';
import { connect } from 'react-redux';
import Modal from '../Modal.jsx';
import { WaveChooser, OnOffButton, ADSR } from './DeviceParts.jsx';
import { WAVES, PROPERTIES } from '../../constants.js';
import PropertyHelper from '../../PropertyHelper.js'
import Slider from '../Slider.jsx';
import { editPropertyFromUI } from '../../actions/properties.js';

class Synth extends React.Component {
  constructor() {
    super();
  }
  _getWaveChooser(propertyId, playPosition) {
    let onSelect = (value) => this.props.dispatch(editPropertyFromUI(propertyId, value));
    let propertyStore = this.props.propertyStore;
    let property = propertyStore[propertyId];
    let currentValue = PropertyHelper.getCurrentValueAndValueDirectlyAfter(property, playPosition).currentValue / property.unit
    return <WaveChooser waves={WAVES} selectedWave={currentValue} onSelect={onSelect} />
  }
  _getSlider(propertyId, playPosition) {
    let onInput = (e) => this.props.dispatch(editPropertyFromUI(propertyId, e.target.value));
    let propertyStore = this.props.propertyStore;
    let property = propertyStore[propertyId];
    let currentValueDisplay = PropertyHelper.getCurrentValueAndValueDirectlyAfter(property, playPosition).currentValue;
    let currentValue = currentValueDisplay / property.unit;
    return <Slider label={property.name + ": " + Number(currentValueDisplay).toPrecision(3)} onChange={() => {}} onInput={onInput} min={property.min} max={property.max} step={1} value={currentValue} />
  }
  _getADSR(deviceProperties, playPosition) {
    let onInput = (propertyId, value) => {
      this.props.dispatch(editPropertyFromUI(propertyId, value));
     }

    let propertyStore = this.props.propertyStore;
    let a = propertyStore[deviceProperties[PROPERTIES.attack]];
    let d = propertyStore[deviceProperties[PROPERTIES.decay]];
    let s = propertyStore[deviceProperties[PROPERTIES.sustain]];
    let r = propertyStore[deviceProperties[PROPERTIES.release]];

    return <ADSR attack={a} decay={d} sustain={s} release={r} playPosition={playPosition} onInput={onInput} />
  }
  _getFMADSR(deviceProperties, playPosition) {
    let onInput = (propertyId, value) => {
      this.props.dispatch(editPropertyFromUI(propertyId, value));
     }

    let propertyStore = this.props.propertyStore;
    let a = propertyStore[deviceProperties[PROPERTIES.fmAttack]];
    let d = propertyStore[deviceProperties[PROPERTIES.fmDecay]];
    let s = propertyStore[deviceProperties[PROPERTIES.fmSustain]];
    let r = propertyStore[deviceProperties[PROPERTIES.fmRelease]];

    return <ADSR attack={a} decay={d} sustain={s} release={r} playPosition={playPosition} onInput={onInput} />
  }
  _getSlideButton(propertyId, playPosition) {
    let onClick = (value) => this.props.dispatch(editPropertyFromUI(propertyId, value));
    let propertyStore = this.props.propertyStore;
    let property = propertyStore[propertyId];
    let currentValue = PropertyHelper.getCurrentValueAndValueDirectlyAfter(property, playPosition).currentValue / property.unit;
    return <OnOffButton name={"Slide"} onClick={onClick} isOn={currentValue} />

  }
  render() {
    let { deviceData, propertyStore, playPosition } = this.props;
    // object where values are ids of property objects in the propertyStore
    let deviceProperties = deviceData.properties;

    let slideButton = this._getSlideButton(deviceProperties[PROPERTIES.slide], playPosition);
    let slideTimeSlider = this._getSlider(deviceProperties[PROPERTIES.slideTime], playPosition);
    let volumeSlider = this._getSlider(deviceProperties[PROPERTIES.volume], playPosition);
    let multiSlider = this._getSlider(deviceProperties[PROPERTIES.multi], playPosition);
    let waveChooser = this._getWaveChooser(deviceProperties[PROPERTIES.wave], playPosition)
    let ADSRSetter = this._getADSR(deviceProperties, playPosition);

    let isFMSlider = this._getSlider(deviceProperties[PROPERTIES.isFM], playPosition);
    let FMWaveChooser = this._getWaveChooser(deviceProperties[PROPERTIES.fmWave], playPosition)
    let FMADSRSetter = this._getFMADSR(deviceProperties, playPosition);
    let fmRatioSlider = this._getSlider(deviceProperties[PROPERTIES.fmRatio], playPosition);
    let fmMaxSlider = this._getSlider(deviceProperties[PROPERTIES.fmMax], playPosition);

    return (
      <div className="synth">
        <div className="synthTopRow">
          <h3>FM Synth</h3>
          { volumeSlider }
        </div>
        <div className="synthRow">
          { slideButton }
          { slideTimeSlider }
          { multiSlider }
          { isFMSlider }
        </div>
        { waveChooser }
        { ADSRSetter }
        <h3>Modulator</h3>
        { FMWaveChooser }
        { FMADSRSetter }
        { fmRatioSlider }
        { fmMaxSlider }
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    propertyStore: state.properties,
    playPosition: state.song.playPosition
  }
}

export default connect(mapStateToProps)(Synth);
