import constants from '../constants.js';
import { ACTIONS, VIEWMODES, PROPERTIES } from '../constants.js';
import PropertyHelper from '../PropertyHelper.js';
import TrackHelper from '../TrackHelper';

let defaultState = {
  lastTrackId: -Number.MAX_SAFE_INTEGER,
  // refers to position in tracks array
  selectedTrack: null,
  tracks: {

  },
};


let storedTrack = JSON.stringify({
  id: null,
  viewMode: VIEWMODES.sequencer,
  currentAction: null,
  nextNoteId: 0,
  // show key refers to the pitch that is showing at the bottom of the sequencer
  showingValue: 42,
  // showingTime refers to the temporal, left - right view
  showingTime: 0,
  instrumentId: null,
  // the keys in notes should be the ids of notes. The values in the note objects should
  // have a key and a start point and an end point (and possibly velocity?). Any change in a notes data
  // should trigger an update in the 'points' object below
  notes: {
  },
  selectedNotes: [],
  // the keys in points are points along the track, which is broken into 32nds.
  // if a note overlies the point, its id will be included in the value, which is a string
  // like this: 15.24.125.212. <- 4 ids of notes playing at that point in the track
  points: {
    // points
  },
  // showing property is the property that shows in the property viewer
  selectedProperty: null,

  // the values should be ids of properties in the properties reducer
  properties: {
    [PROPERTIES.pitch]: null,
  },

  undos: [],
  // lastShowingTime and lastShowingValue are used
  // for reference when adjusting the placement of the view window
  // both for sequencer view and properties view
  lastShowingTime: 0,
  lastShowingValue: 42,

  magnification: 1,

  // startSelectingValue and time are used when you are dragging a selection box.
  // they refer to the origin point of the drag
  startSelectingValue: null,
  startSelectingTime: null,
  // these are also used like the above 2 but they refer to the current drag
  // position, not the origin point
  currentSelectingValue: null,
  currentSelectingTime: null
});

let undoCreate = (track, noteId) => {
  let note = track.notes[noteId];
  track.selectedNotes = track.selectedNotes.filter((id) => id != noteId);
  for (let i = note.timeStart; i < note.timeEnd; i++) {
    delete track.points[i][noteId];
  }
  delete track.notes[noteId];
}

let undoDelete = (track, notes) => {
  for (let noteId in notes) {
    let note = notes[noteId];
    if (note.selected) {
      track.selectedNotes.push(noteId);
    }
    track.notes[noteId] = note;
    for (let i = note.timeStart; i < note.timeEnd; i++) {
      track.points[i] = track.points[i] || {};
      track.points[i][noteId] = true;
    }
  }
}

let undoCut = (track, originalId, newId) => {
  let oldNote = track.notes[originalId];
  let newNote = track.notes[newId];
  oldNote.timeEnd = newNote.timeEnd;
  for (let i = oldNote.timeStart; i < oldNote.timeEnd; i++) {
    track.points[i] = track.points[i] || {};
    track.points[i][originalId] = true;
    delete track.points[i][newId];
  }
  if (newNote.selected) {
    let selIndex = track.selectedNotes.indexOf(newId);
    if (selIndex != -1) {
      track.selectedNotes.splice(selIndex, 1);
    }
  }
  delete track.notes[newId];
}

let undoMoveOrExpand = (track, notes) => {
  for (let noteId in notes) {
    let note = notes[noteId];
    let currentNote = track.notes[noteId];
    for (let i = currentNote.timeStart; i < currentNote.timeEnd; i++) {
      track.points[i] = track.points[i] || {};
      delete track.points[i][noteId];
    }
    for (let i = note.timeStart; i < note.timeEnd; i++) {
      track.points[i] = track.points[i] || {};
      track.points[i][noteId] = true;
    }
    currentNote.timeStart = note.timeStart;
    currentNote.timeEnd = note.timeEnd;
    currentNote.key = note.key;
  }
}

let undoPaste = (track, notes) => {
  for (let noteId in notes) {
    let note = notes[noteId];
    let currentNote = track.notes[noteId];
    for (let i = currentNote.timeStart; i < currentNote.timeEnd; i++) {
      track.points[i] = track.points[i] || {};
      delete track.points[i][noteId];
    }
    delete track.notes[noteId];
  }
  track.selectedNotes = track.selectedNotes.filter((noteId) => {
    return !notes[noteId];
  })
}


export default (state = defaultState, action) => {
  switch (action.type) {
    case 'addNewTrack': {
      let newState = {
        ...state
      };
      newState.tracks = {
        ...newState.tracks
      };
      let trackId = ++newState.lastTrackId;
      let newTrack = JSON.parse(storedTrack);
      newTrack.id = trackId;
      newTrack.instrumentId = action.instrumentId;
      newTrack.properties[PROPERTIES.pitch] = action.pitchId;
      newTrack.properties[PROPERTIES.volume] = action.volumeId;
      newState.tracks[trackId] = newTrack;
      newState.selectedTrack =trackId;
      newState.selectedProperty = action.pitchId;
      return newState;
    }
    case 'setSelectedTrack': {
      let { newState } = TrackHelper.getCopiedState(state);
      newState.selectedTrack = action.index;
      return newState;
      break;
    }
    case 'setSelectedProperty': {
      let { newState, track } = TrackHelper.getCopiedState(state);
      track.selectedProperty = action.id;
      return newState;
    }
    case 'setViewMode': {
      let { newState, track } = TrackHelper.getCopiedState(state);
      track.viewMode = action.mode;
      return newState;
    }
    case 'changeZoom': {
      let { newState, track } = TrackHelper.getCopiedState(state);
      track.magnification += action.zoomDiff;
      if (track.magnification < 1) {
        track.magnification = 1;
      }
      return newState;
      break;
    }
    case 'setCurrentAction': {
      let { newState, track } = TrackHelper.getCopiedState(state);
      track.currentAction = action.currentAction;
      return newState;
    }
    case 'startSelectingPoint': {
      let { newState, track } = TrackHelper.getCopiedState(state);
      track.currentAction = ACTIONS.selecting;
      track.startSelectingValue = action.startSelectingValue;
      track.startSelectingTime = action.startSelectingTime;
      track.currentSelectingValue = action.startSelectingValue;
      track.currentSelectingTime = action.startSelectingTime;
      return newState;
    }
    case 'setCurrentSelectingPoint': {
      let { newState, track } = TrackHelper.getCopiedState(state);
      track.currentSelectingValue = action.currentSelectingValue;
      track.currentSelectingTime = action.currentSelectingTime;
      return newState;
    }
    case 'setSelectedNotes': {
      let { newState, track } = TrackHelper.getCopiedState(state);
      TrackHelper.resetSelectedNotes(track, action.ids);
      return newState;
    }
    case 'setMagnification': {
      let { newState } = TrackHelper.getCopiedState(state);
      newState.magnification = action.magnification;
      return newState;
    }
    case 'beginAdjustingSequencerWindow': {
      let { newState, track } = TrackHelper.getCopiedState(state);
      track.lastShowingValue = action.showingValue;
      track.lastShowingTime = action.showingTime;
      return newState;
    }
    case 'adjustSequencerWindow': {
      let { newState, track } = TrackHelper.getCopiedState(state);
      track.showingTime = action.time;

      if (action.noteValue !== null) {
        track.showingValue = action.noteValue;
      }

      if (action.adjustLastValues) {
        track.lastShowingTime = action.time;
        track.lastShowingValue = action.noteValue || action.propertyValue || 0;
      }

      return newState;
    }
    case 'createNote': {
      let { newState, track } = TrackHelper.getCopiedState(state);
      let noteId = track.nextNoteId++;
      let note = TrackHelper.getNewNote(noteId, action.key, action.timeStart, action.timeEnd);
      track.notes[noteId] = note;
      TrackHelper.resetSelectedNotes(track, [noteId]);
      track.currentAction = ACTIONS.expandRight;
      track.undos.push({ type: 'create', id: noteId });
      // immediatly after creating you are also expanding it by dragging left and right
      TrackHelper.prepareSelectionForExpanding(track, noteId);

      for (let i = note.timeStart; i < note.timeEnd; i++) {
        track.points[i] = track.points[i] || {};
        track.points[i][noteId] = true;
      }
      return newState;
    }
    case 'startExpandingSelections': {
      let { newState, track } = TrackHelper.getCopiedState(state);
      let undoObject = { type: 'expand', notes: {} }
      track.selectedNotes.forEach((noteId) => {
        undoObject.notes[noteId] = {
          ...track.notes[noteId]
        }
        TrackHelper.prepareSelectionForExpanding(track, noteId, action.isLeft);
      });
      track.undos.push(undoObject);
      track.currentAction = action.isLeft ? ACTIONS.expandLeft : ACTIONS.expandRight;
      return newState;
    }
    // this one doesn't require undo because it is handled by undoCreate
    case 'startExpandingNewSelection': {
      let { newState, track } = TrackHelper.getCopiedState(state);
      TrackHelper.prepareSelectionForExpanding(track, action.selectionId, action.isLeft);
      track.currentAction = action.isLeft ? ACTIONS.expandLeft : ACTIONS.expandRight;
      TrackHelper.resetSelectedNotes(track, [action.selectionId]);
      return newState;
    }
    case 'startMovingNotes': {
      let { newState, track } = TrackHelper.getCopiedState(state);
      let undoObject = { type: "move", notes: {}};
      track.selectedNotes.forEach((noteId) => {
        undoObject.notes[noteId] = {
          ...track.notes[noteId]
        };
        TrackHelper.prepareSelectionForMoving(track, noteId);
      });
      track.undos.push(undoObject);
      track.currentAction = ACTIONS.moving;
      return newState;
    }
    case 'startMovingNewlySelectedNote': {
      let { newState, track } = TrackHelper.getCopiedState(state);
      let undoObject = { type: "move", notes: {}};
      undoObject.notes[action.selectionId] = {
        ...track.notes[action.selectionId]
      }
      track.undos.push(undoObject);
      TrackHelper.prepareSelectionForMoving(track, action.selectionId);
      track.currentAction = ACTIONS.moving;
      TrackHelper.resetSelectedNotes(track, [action.selectionId]);
      return newState;
    }
    case 'cutNote': {
      let { newState, track } = TrackHelper.getCopiedState(state);
      let cutNote = track.notes[action.noteId];
      let noteId = track.nextNoteId++;
      let note = TrackHelper.getNewNote(noteId, cutNote.key, action.time, cutNote.timeEnd);
      cutNote.timeEnd = action.time;
      track.notes[noteId] = note;
      for (let i = note.timeStart; i < note.timeEnd; i++) {
        track.points[i] = track.points[i] || {};
        track.points[i][noteId] = true;
        delete track.points[i][action.noteId];
      }
      track.undos.push({
        type: "cutNote",
        originalId: action.noteId,
        newId: noteId,
      });
      return newState;
    }
    case 'deleteSelectedNotes': {
      let { newState, track } = TrackHelper.getCopiedState(state);
      let undoObject = { type: 'delete', notes: {} };
      track.selectedNotes.forEach((noteId) => {
        let note = track.notes[noteId];
        undoObject.notes[noteId] = {
          ...note
        }
        for (let i = note.timeStart; i < note.timeEnd; i++) {
          delete track.points[i][noteId];
        };
        delete track.notes[noteId];
      });
      track.undos.push(undoObject);
      track.selectedNotes = [];
      return newState;
    }
    case 'editNotes': {
      let { newState, track } = TrackHelper.getCopiedState(state);
      track.selectedNotes.forEach((noteId) => {
        let currentNote = track.notes[noteId];
        for (let i = currentNote.timeStart; i < currentNote.timeEnd; i++) {
          delete track.points[i][noteId];
        }
        currentNote.key = action.notes[noteId].key;
        currentNote.timeStart = action.notes[noteId].timeStart;
        currentNote.timeEnd = action.notes[noteId].timeEnd;
        for (let i = currentNote.timeStart; i < currentNote.timeEnd; i++) {
          track.points[i] = track.points[i] || {};
          track.points[i][noteId] = true;
        }
      });
      return newState;
    }
    case 'pasteCopiedItems': {
      let { newState, track } = TrackHelper.getCopiedState(state);
      let { referencePoint, items } = action.copyObject;
      let timeDiff = referencePoint.time - track.showingTime - 5;
      let keyDiff = referencePoint.key - (track.showingValue + constants.keysOnScreen) + 5;
      let undoObject = { type: 'paste', notes: {} };
      let newSelectedNotes = [];
      items.forEach((item) => {
        let noteId = track.nextNoteId++;
        newSelectedNotes.push(noteId);
        let newNote = {
          id: noteId,
          timeStart: item.timeStart - timeDiff,
          timeEnd: item.timeEnd - timeDiff,
          key: item.key - keyDiff
        }
        track.notes[noteId] = newNote;
        for (let i = newNote.timeStart; i < newNote.timeEnd; i++) {
          track.points[i] = track.points[i] || {};
          track.points[i][noteId] = true;
        }
        undoObject.notes[noteId] = true;
      })
      TrackHelper.resetSelectedNotes(track, newSelectedNotes);
      track.undos.push(undoObject);
      return newState;
    }
    case 'undo': {
      let { newState, track } = TrackHelper.getCopiedState(state);
      if (track.undos.length == 0) {
        return state;
      }
      let undo = track.undos.pop();
      switch (undo.type) {
        case 'create': {
          undoCreate(track, undo.id);
          break;
        }
        case 'delete': {
          undoDelete(track, undo.notes);
          break;
        }
        case 'cutNote': {
          undoCut(track, undo.originalId, undo.newId);
          break;
        }
        case 'move': {
          undoMoveOrExpand(track, undo.notes);
          break;
        }
        case 'expand': {
          undoMoveOrExpand(track, undo.notes);
          break;
        }
        case 'paste': {
          undoPaste(track, undo.notes);
          break;
        }
      }
      return newState;
    }
    default: {
      return state;
    }
  }
}
