import React from 'react';
import { NavLink } from 'react-router-dom';

class Header extends React.Component {
  render() {
    return (
      <header>
        <div className="navButtons">
          <NavLink className="logo" to="/">GORAH MIDI EDITOR</NavLink>
        </div>
      </header>
    )
  }
}

export default Header;
