import constants from '../constants.js';
import { POSITIONS, PROPERTIES } from '../constants.js';

let defaultState = {
  // refers to position in tracks array
  name: "untitled",
  playPosition: 0,
  playing: false,
  properties: {
    [PROPERTIES.bpm]: null,
    [PROPERTIES.volume]: null,
  },
  startPosition: 16,
  endPosition: 80,
  adjustingValue: null,
  adjustingStartPosition: null,
  // the object with copied items in it
  copyObject: null,
  // the note that user is inputting manually with speaker tool
  playingNote: null,
};

const makeCopyObject = (selectedNotes) => {
  let items = [];
  // we need a reference point that each pasted item
  // will be drawn in relation to. So if we choose to draw
  // at A4, at 4.5 beats, we go through each copied note
  // take the difference between it's coordinates and the
  // reference point, and add it to A4, 4.5.
  let referencePoint = {
    key: -100,
    time: 1000000000000,
  }
  selectedNotes.forEach((note) => {
    if (note.timeStart < referencePoint.time) {
      referencePoint.time = note.timeStart;
    }
    if (note.key > referencePoint.key) {
      referencePoint.key = note.key;
    }
    items.push({
      ...note,
      id: null,
    });
  });
  return {
    items,
    referencePoint
  }
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'togglePlaying': {
      let newState = {
        ...state,
        playing: !state.playing
      }
      return newState;
    }
    case 'initializeSongProperties':  {
      let newState = {
        ...state
      }
      newState.properties[PROPERTIES.bpm] = action.bpmId;
      newState.properties[PROPERTIES.volume] = action.volumeId;
      return newState;
    }
    case 'setBPM': {
      let newState = {
        ...state,
        bpm: action.bpm
      }
      return newState;
    }
    case 'advancePlayPosition': {
      let newState = {
        ...state,
        playPosition: action.position,
      }
      return newState;
    }
    case 'startAdjustingPlayPosition': {
      let newState = {
        ...state,
        playPosition: action.position,
        adjustingValue: POSITIONS.playPosition,
        adjustingStartPosition: action.position
      }
      return newState;
    }
    case 'startAdjustingStartPosition': {
      let newState = {
        ...state,
        adjustingValue: POSITIONS.startPosition,
        adjustingStartPosition: state.startPosition
      }
      return newState;
    }
    case 'startAdjustingEndPosition': {
      let newState = {
        ...state,
        adjustingValue: POSITIONS.endPosition,
        adjustingStartPosition: state.endPosition
      }
      return newState;
    }
    case 'adjustPositionValue': {
      let newState = {
        ...state,
      }
      if (state.adjustingValue == POSITIONS.playPosition) {
        newState.playPosition = action.time;
      } else if (state.adjustingValue == POSITIONS.startPosition) {
        newState.startPosition = action.time;
      } else if (state.adjustingValue == POSITIONS.endPosition) {
        newState.endPosition = action.time
      }
      return newState;
    }
    case 'copySelection': {
      let newState = {
        ...state,
        copyObject: makeCopyObject(action.selectedNotes)
      }
      return newState;
    }
    case 'playingNote': {
      let newState = {
        ...state,
        playingNote: {
          key: action.key,
          timeStart: state.playPosition,
        },
      }
      return newState;
    }
    default: {
      return state;
    }
  }
}
