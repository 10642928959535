import { combineReducers } from 'redux';
import test from './test.js';
import environment from './environment.js';
import tracks from './tracks.js';
import song from './song.js';
import tools from './tools.js';
import properties from './properties.js';
import audioDevices from './audioDevices.js';

export default combineReducers({
  test,
  environment,
  tracks,
  song,
  tools,
  properties,
  audioDevices,
});
