import React from 'react';
import { connect } from 'react-redux';
import constants from '../constants.js';
import { ACTIONS } from '../constants.js';
import Grid from './Grid.jsx';
import MouseManager from '../MouseManager.js';
import PropertyHelper from '../PropertyHelper.js';
import {
  beginAdjustingSequencerWindow,
  adjustSequencerWindow,
  startSelectingPoint,
  setCurrentSelectingPoint,
  setCurrentAction,
  undo,
} from '../actions/tracks.js';
import {
  createPropertyValue,
  setSelectedPoints,
  startMovingPropertyPoints,
  startMovingNewlySelectedPropertyPoint,
  deleteSelectedPropertyPoints,
  editPropertyPoints,
  setSelectedProperty,
} from '../actions/properties.js';

class PropertyViewer extends React.Component {
  constructor() {
    super();

    this._canvases = {};
    this._viewer = null;
    // after we figure out what points are on screen we store them here
    // so we don't have to search for them again
    this._showingPoints = [];
  }
  componentDidMount() {
    this._drawToCanvas();
    this._mouse = new MouseManager(
      this._viewer,
      this.didStartDrag.bind(this),
      this.didDrag.bind(this),
      this.didLetGo.bind(this),
      this.didWheel.bind(this),
      this.didMove.bind(this)
    );
    this._select.addEventListener('mousedown', (e) => e.stopPropagation())
    this._checkForHyperDrag();
  }
  componentDidUpdate() {
    this._drawToCanvas();
  }
  componentWillUnmount() {
    this._mouse.destroy();
    clearTimeout(this._timeout);
  }
  _moveWindow(valueDiff, timeDiff) {
    let track = this.props.track;
    let propertyInfo = this.props.propertyInfo;
    let newTime = track.lastShowingTime + timeDiff;
    if (newTime < 0) { newTime = 0 };
    let newValue = track.lastShowingValue + valueDiff;
    //if (newKey < 0) { newKey = 0 };
    this.props.dispatch(adjustSequencerWindow(null, newValue, newTime));
  }
  _getTimeAndValueForPoint(point) {
    let track = this.props.track;
    let propertyInfo = this.props.propertyInfo;
    let height = constants.keysOnScreen * constants.noteHeight;

    let yDiff = height - point.y;
    let value = Math.round(yDiff / constants.noteHeight) + propertyInfo.showingValue;

    let time = track.showingTime + Math.round(point.x / (track.magnification * constants.minNoteDivisionWidth));
    let preciseTime = track.showingTime + point.x / (track.magnification * constants.minNoteDivisionWidth);

    return {
      time,
      value
    };
  }
  _getTimeAndValueMovement(movementVector) {
    let track = this.props.track;
    let magnification = track.magnification;

    let height = constants.keysOnScreen * constants.noteHeight;

    let valueDiff = Math.floor(movementVector.y / constants.noteHeight);

    let noteWidth = constants.minNoteDivisionWidth * magnification;
    let timeDiff =  Math.floor(-movementVector.x / noteWidth);
    return { timeDiff, valueDiff }
  }
  _checkForHyperDrag() {

  }
  didStartDrag(points) {

    let track = this.props.track;
    let propertyInfo = this.props.propertyInfo;
    let { time, value, preciseTime } = this._getTimeAndValueForPoint(points.boxPoint);

    switch (this.props.tool) {
      case 'hand': {
        this.props.dispatch(beginAdjustingSequencerWindow(propertyInfo.showingValue, track.showingTime));
        break;
      }
      case 'pencil': {
        this.props.dispatch(createPropertyValue(value, time, track.showingTime));
        break;
      }
      case 'slice': {
        break;
      }
      case 'select': {
        // find which values in this section of track we pressed
        let clickedPoints = this._showingPoints.reduce((filtered, point) => {
          if (point.time == time && point.value == value) {
            filtered.push(point);
          };
          return filtered;
        }, []);

        if (clickedPoints.length == 0) {
          // we did not click a note
          this.props.dispatch(startSelectingPoint(value, time))
          return;
        }
        // get last instance of point with this time and value
        let topPoint = clickedPoints[clickedPoints.length - 1];
        if (!PropertyHelper.pointIsSelected(propertyInfo, topPoint.id)) {
          this.props.dispatch(startMovingNewlySelectedPropertyPoint(topPoint.id, true, track.showingTime))
        } else {
          this.props.dispatch(startMovingPropertyPoints(true, track.showingTime));
        }
        break;
      }
      case 'speaker': {
        break;
      }
      default: {
        return true;
      }
    }
  }
  didDrag(points) {
    this.setState({ showResizeCursor: false });

    let { time, value, preciseTime } = this._getTimeAndValueForPoint(points.boxPoint);
    let {timeDiff, valueDiff} = this._getTimeAndValueMovement(points.movement);
    let track = this.props.track;
    let propertyInfo = this.props.propertyInfo;
    let propertyAction = this.props.propertyAction;

    switch (this.props.tool) {
      case 'hand': {
        this._moveWindow(valueDiff, timeDiff);
        break;
      }
      case 'pencil': {
        if (points.hyperDragX != this.state.hyperDragX || points.hyperDragY != this.state.hyperDragY) {
          this.setState({ hyperDragX: points.hyperDragX, hyperDragY: points.hyperDragY});
        } else {
          this._changePoint(valueDiff, timeDiff);
        }
        break;
      }
      case 'select': {
        if (points.hyperDragX != this.state.hyperDragX || points.hyperDragY != this.state.hyperDragY) {
          this.setState({ hyperDragX: points.hyperDragX, hyperDragY: points.hyperDragY});
        }  else if (track.currentAction == ACTIONS.selecting) {
          this.props.dispatch(setCurrentSelectingPoint(value, time));
        } else if (propertyAction == ACTIONS.moving) {
          this._changePoint(valueDiff, timeDiff);
        }
        break;
      }
      case 'speaker': {
        //this.props.dispatch(playingNote(key));
        return;
        break;
      }
      default: {
        return true;
      }
    }
  }
  didLetGo(points) {
    let track = this.props.track;
    let propertyInfo = this.props.propertyInfo;
    this.setState({
      hyperDragY: 0,
      hyperDragX: 0,
      hyperDragXSum: 0,
      hyperDragYSum: 0,
      moving: false,
    });
    switch (this.props.tool) {
      case 'hand': {
        // just sets the lastShowingTime and lastShowingValue to the current values
        this.props.dispatch(adjustSequencerWindow(null, propertyInfo.showingValue, track.showingTime, true));
        break;
      }
      case 'select': {
        if (track.currentAction == ACTIONS.selecting) {
          let selected = PropertyHelper.getPointsInSelectionRange(propertyInfo,track);
          this.props.dispatch(setSelectedPoints(selected));
        }
        break;
      }
      case 'speaker': {
        this.props.dispatch(playingNote(null));
        return;
        break;
      }
    }
    this.props.dispatch(setCurrentAction(null));
  }
  didWheel(points) {
    let track = this.props.track;
    let propertyInfo = this.props.propertyInfo;
    let {timeDiff, valueDiff} = this._getTimeAndValueMovement(points.movement);

    let newTime = track.showingTime + timeDiff;
    if (newTime < 0) { newTime = 0 };
    let newValue = propertyInfo.showingValue + valueDiff;
    //if (newKey < 0) { newKey = 0 };
    this.props.dispatch(adjustSequencerWindow(null, newValue, newTime, true));
  }
  didMove() {

  }
  // this has to traverse the selected points and move each,
  // making sure not to move past its neighbours
  _changePoint(valueDiff, timeDiff) {
    let track = this.props.track;
    let propertyInfo = this.props.propertyInfo;

    let selectedNumber = propertyInfo.selectedPoints.length;
    let movedLeft = timeDiff > 0;
    let lastPoint = null;
    let points = []
    for (let i = 0; i < propertyInfo.selectedPoints.length; i++) {
      let pointId = movedLeft ? propertyInfo.selectedPoints[i] : propertyInfo.selectedPoints[selectedNumber - 1 - i];
      let point = {
        ...propertyInfo[pointId]
      };
      point.value = point.clickedValue - valueDiff;
      point.time = point.clickedTime - timeDiff;
      let comparisonPoint = lastPoint ? lastPoint : (movedLeft ? propertyInfo[point.from] : propertyInfo[point.to]);

      if (point.id == 'start') {
        point.time = 0;
      } else if (!comparisonPoint) {
        // could be at beginning or end here
      } else {
        if (movedLeft && point.time < comparisonPoint.time) {
          point.time = comparisonPoint.time;
        } else if (!movedLeft && point.time > comparisonPoint.time) {
          point.time = comparisonPoint.time;
        }
      }
      points.push(point);
      lastPoint = point;
    }
    this.props.dispatch(editPropertyPoints(points, propertyInfo.id))
  }
  _changeNotePlacement(keyDiff, timeDiff) {
    let track = this.props.track;
    let notes = {};
    track.selectedNotes.forEach((noteId) => {
      let note = track.notes[noteId];
      let length = note.timeEnd - note.timeStart;
      let newKey = note.clickedKey - keyDiff + this.state.hyperDragYSum;
      let newStart = note.clickedTime - timeDiff + this.state.hyperDragXSum;
      let newEnd = newStart + length;
      notes[noteId] = { key: newKey, timeStart: newStart, timeEnd: newEnd };
    });
    this.props.dispatch(editNotes(notes));
  }
  _drawToCanvas() {
    let track = this.props.track;
    let propertyInfo = this.props.propertyInfo;
    let height = constants.keysOnScreen * constants.noteHeight;
    let showingTime = track.showingTime;
    let timeWidth = constants.minNoteDivisionWidth;
    let maxPosition = showingTime + constants.viewWidth / (timeWidth * track.magnification);
    this._showingPoints = PropertyHelper.getShowingPropertyPoints(propertyInfo, track);
    let selectedPoints = propertyInfo.selectedPoints;
    let midPoint = height + propertyInfo.showingValue * constants.noteHeight;
    for (let key in this._canvases) {
      let canvas = this._canvases[key];
      let ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      ctx.strokeStyle = "red";
      ctx.beginPath();
      ctx.moveTo(0, midPoint);
      ctx.lineTo(canvas.width, midPoint);
      ctx.stroke();
      ctx.closePath();

      let lastPoint = null;
      this._showingPoints.forEach((point, index) => {
        let x = (point.time - showingTime) * timeWidth * track.magnification;
        let y = midPoint - constants.noteHeight * point.value;
        if (index == 0) {
          lastPoint = {x, y};
        } else {
          ctx.beginPath();
          ctx.strokeStyle = "black";
          ctx.moveTo(lastPoint.x, lastPoint.y);
          ctx.lineTo(x, y);
          ctx.stroke();
          ctx.closePath();
        }
        if (index == this._showingPoints.length - 1 && point.time < maxPosition) {
          ctx.beginPath();
          ctx.strokeStyle = "black";
          ctx.moveTo(x, y);
          ctx.lineTo(canvas.width, y);
          ctx.stroke();
          ctx.closePath();
        }
        ctx.beginPath();
        if (selectedPoints.indexOf(point.id) != -1) {
          ctx.strokeStyle = "red";
        }
        ctx.arc(x, y, 2, 0, 2 * Math.PI);
        ctx.stroke();
        ctx.closePath();
        ctx.moveTo(x, y);
        lastPoint = {x, y};
      });
    }
  }
  _getCanvases(points) {
    let canvasMax = 100;
    let remainingWidth = constants.viewWidth;
    let height = constants.keysOnScreen * constants.noteHeight;
    return [<canvas key={0} ref={(el) => this._canvases[0] = el} width={remainingWidth} height={height} />];
    let canvases = [];
    let id = 0;
    while (remainingWidth > 0) {
      let canId = id++;
      let width;
      if (remainingWidth < canvasMax) {
        width = remaingWidth;
      } else {
        width = canvasMax;
      }
      remainingWidth -= canvasMax;
      canvases.push(<canvas key={canId} ref={(el) => this._canvases[canId] = el} width={width} height={height} />)
    }
    return canvases;
  }
  _getLines() {
    let track = this.props.track;
    let propertyInfo = this.props.propertyInfo;
    let showingValue = propertyInfo.showingValue;
    let lines = [];
    for (let i = showingValue; i < showingValue + constants.keysOnScreen; i++) {
      let className = "";
      if (propertyInfo.max != null) {
        className = i > propertyInfo.max ? "hidden" : "";
      }
      if (propertyInfo.min != null) {
        className = i < propertyInfo.min ? "hidden" : className;
      }
      let displayValue = Number(i * propertyInfo.unit).toPrecision(2);
      lines.push(<Line classModifier={className} key={i} value={displayValue} magnification={track.magnification} startPoint={track.showingTime}/>);
    }
    return lines;
  }
  _getSelectionBox() {
    let track = this.props.track;
    let propertyInfo = this.props.propertyInfo;
    if (track.currentAction !== ACTIONS.selecting) {
      return null;
    }
    let style = {
      left: (Math.min(track.startSelectingTime, track.currentSelectingTime) - track.showingTime) * constants.minNoteDivisionWidth * track.magnification + 'px',
      top: '0px',
      width: Math.abs(track.currentSelectingTime - track.startSelectingTime) * constants.minNoteDivisionWidth * track.magnification + 'px',
      height: '100%',
    };
    let selectionBox = (
      <div className="sequencerSelectionBox"
           style={style}>
      </div>
    );
    return selectionBox;
  }
  _setSelectedProperty(e) {
    let propertyId = e.target.value;
    this.props.dispatch(setSelectedProperty(propertyId));
  }
  _getPropertyOptions() {
    let options = [];
    let track = this.props.track;
    let trackProperties = track.properties;
    for (let key in trackProperties) {
      let propertyId = trackProperties[key];
      let message = `Track -- ${key}`;
      options.push(<option key={message} value={propertyId}>{message}</option>);
    }
    let instrument = this.props.audioDevices[track.instrumentId];
    while (true) {
      for (let key in instrument.properties) {
        let propertyId = instrument.properties[key];
        let message = `${instrument.name} -- ${key}`;
        options.push(<option key={message} value={propertyId}>{message}</option>);
      }
      if (instrument.nextDevice == null) {
        break;
      }
    }
    return (
      <select onChange={() => {}} value={track.selectedProperty} onInput={this._setSelectedProperty.bind(this)} ref={(el) => this._select = el } className="propertyViewerLabel">
        {
          options
        }
      </select>
    );
  }
  render() {
    let track = this.props.track;
    let propertyInfo = this.props.propertyInfo;
    let showingTime = track.showingTime;
    let magnification = track.magnification;


    let canvases = this._getCanvases();

    let lines = this._getLines();

    let selectionBox = this._getSelectionBox();

    let wrapperStyle = {
      width: constants.viewWidth + 'px',
    }
    let viewerStyle = {
      height: constants.keysOnScreen * constants.noteHeight + 'px',
    }
    return (
      <div style={wrapperStyle} className="propertyViewer">
        <div ref={(el) => this._viewer = el} style={viewerStyle} className="propertyViewerWindow">
          <Grid magnification={magnification} showingTime={showingTime} />
          <div className="propertyViewerLines">
            { lines.reverse() }
          </div>
          { canvases }
          { selectionBox }
          { this._getPropertyOptions() }
        </div>
      </div>
    )
  }
}

let mapStateToProps = (state, props) => {
  return {
    selectedTrack: state.tracks.selectedTrack,
    track: state.tracks.tracks[state.tracks.selectedTrack],
    propertyInfo: state.properties[state.properties.selectedProperty],
    propertyAction: state.properties.currentAction,
    tool: state.tools.selectedTool,
    playing: state.song.playing,
    audioDevices: state.audioDevices
  }
}

export default connect(mapStateToProps)(PropertyViewer);

const Line = ({classModifier,   magnification, value, startPoint }) => {
  let barWidth = constants.minNoteDivision * constants.minNoteDivisionWidth * magnification;
  let barsOnScreen = Math.floor(constants.viewWidth / barWidth) + 1;
  // we need to check so we don't divide by zero
  let drawStart = startPoint;
  let drawEnd = drawStart + constants.minNoteDivision * barsOnScreen;

  let height = constants.noteHeight;

  return (
    <div style={{height: `${height}px`}} className={`propertyViewerLine ${classModifier}`}>
      { value }
    </div>
  );
}
