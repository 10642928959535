import constants from './constants.js';

// helper for working with tracks
let TrackHelper = {
  getCopiedState(state) {
    let newState = {
      ...state,
    }
    newState.tracks[newState.selectedTrack] = {
      ...newState.tracks[newState.selectedTrack],
    }
    let track = newState.tracks[newState.selectedTrack];
    return { newState, track };
  },

  resetSelectedNotes(track, newSelectedNotes) {
    track.selectedNotes.forEach((noteId) => {
      track.notes[noteId].selected = false;
    })
    track.selectedNotes = newSelectedNotes;
    track.selectedNotes.forEach((noteId) => {
      track.notes[noteId].selected = true;
    })
  },
  prepareSelectionForExpanding(track, noteId, isLeft) {
    let note = track.notes[noteId];
    note.clickedTime = isLeft ? note.timeStart : note.timeEnd;
    note.clickedKey = note.key;
    note.showingTime = track.showingTime;
    note.showingKey = track.showingKey;
  },
  prepareSelectionForMoving(track, noteId) {
    let note = track.notes[noteId];
    note.clickedTime = note.timeStart;
    note.clickedKey = note.key;
    note.showingTime = track.showingTime;
    note.showingKey = track.showingKey;
  },
  getNewNote(id, key, timeStart, timeEnd) {
    return {
      id,
      key,
      timeStart,
      timeEnd
    }
  },
}

export default TrackHelper;
